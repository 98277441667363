import './index.css'
import App from './App';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import GestionDispositivos from './components/gestionDispositivos';
import Login from './components/login';
import { Provider } from 'react-redux';
import { store } from './store/store';
import Registro from './components/registro';
import Gestion from './components/gestion';
import GestionUsuarios from './components/usuarios';
import Perfil from './components/perfil';

const container = document.getElementById('root');
const root = createRoot(container!); 
root.render(
  <Provider store={store}>
    <BrowserRouter basename='/admin'>
      <Routes>
        <Route path='/' element={<App/>}>
          <Route index element={<Login/>}/>
          <Route path='/panel' element={<GestionDispositivos/>}/>
          <Route path='/registro' element={<Registro/>}/>
          <Route path='/gestion' element={<Gestion/>}/>
          <Route path='/usuarios' element={<GestionUsuarios/>}/>
          <Route path='/perfil' element={<Perfil/>}/>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </Provider>
  );
