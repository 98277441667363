import $ from 'jquery'
import React, { useEffect, useState } from 'react';
import { hubConnection } from 'aspnet-signalr-reactjs';
import { useNavigate } from 'react-router-dom';
import { AuthApiService, OpenAPI } from '../../services/openapi';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setAccessTokenStore, setEstadoUserStore, setFirmaCapturadaBase64, setFirmaMensajeError, setPerfil, setUsernameStore } from '../../store/slices/userSlice';
import { selectConnection, selectRegistro, setConnectionStore, setEstadosStore, setHubProxyStore, setRegistro } from '../../store/slices/connectionSlice';
import './login.css';
import moment from 'moment';

function Login() {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorSession, setErrorSession] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [accessTokenCon, setAccessTokenCon] = useState("");
    const [connection, setConnection] = useState(null)
    const [hubProxy, setHubProxy] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const connectionStore = useAppSelector(selectConnection)

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    
    const URL_WS_TOKEN = "https://clinicassistant.e-osca.com/token";
    const URL_WS_HUB = "https://clinicassistant.e-osca.com/clinicasHub";
    const HUB_NAME = "clinicasHub";
    const USER = "backoffice";
    const PASS = "pruebas123";

    useEffect(() => {
        document.title = "Login | PodoActiva Clinic Assistant"

        // ---- AHORA NO ES NECESARIO ----
        // //Permisos micrófono
        // navigator.mediaDevices.getUserMedia({audio: true}).then((stream) => {
        //     window.localStream = stream;
        //     window.localAudio.srcObject = stream;
        //     window.localAudio.autoplay = true;
        // }).catch((err) => {});

        // //Permisos cámara
        // navigator.mediaDevices.getUserMedia({video: true}).then((stream) => {
        //     window.localStream = stream;
        //     window.localAudio.srcObject = stream;
        //     window.localAudio.autoplay = true;
        // }).catch((err) => {});
        
        authCheck();
    }, [])

    // ---------------- AUTH & SOCKET CHECK ----------------

    async function authCheck () {
        try {
            if (localStorage.getItem("accessToken") === null && localStorage.getItem("username") === null) {
                setIsAuthenticated(false)
                dispatch(setAccessTokenStore(""))
                dispatch(setUsername(""))
                return;
            } else {
                OpenAPI.TOKEN = localStorage.getItem("accessToken");
                let response = await AuthApiService.authApiEchoUser();
                setIsAuthenticated(response.isAuthenticated)
                if (response.perfil !== null) {
                    dispatch(setPerfil(response.perfil))
                }
                if (response.isAuthenticated) {
                    setUsername(localStorage.getItem("username"))
                    if (!connectionStore) {
                        getToken();
                    } else {
                        navigate("/panel");
                    }
                }
            }
            // console.log(response);
        } catch (err) {
            dispatch(setUsernameStore(""))
            dispatch(setAccessTokenStore(""))
            setIsAuthenticated(false)
            if (localStorage.getItem("accessToken") !== null && localStorage.getItem("username") !== null) {
                setErrorSession(`Hola ${localStorage.getItem('username')}. Parece que la sesión anterior ha caducado. Por favor, vuelva a iniciar sesión para acceder.`)
            }
            // localStorage.removeItem("accessToken");
            // localStorage.removeItem("username")
        }
    }


    // ---------------------- LOGIN ------------------------

    async function doLogin(e) {
        e.preventDefault();
        try {
            //TOKEN API LOGIN
            OpenAPI.TOKEN = "";
            let response = await AuthApiService.authApiLogin({username: username, password: password, idExterno: 0, extendedExpiry: true});
            localStorage.setItem("username", username);
            localStorage.setItem("accessToken", response.accessToken);
            if (response.perfil !== null) {
                dispatch(setPerfil(response.perfil))
            }
            //TOKEN WEBSOCKET HUB
            getToken();
        } catch (err) {
            if (password === "" || username === "") {
                setErrorMessage("Debe introducir los datos correctamente.")
            } else {
                setErrorMessage("Usuario y/o contraseña incorrectos.")
            }
        }
    }

    async function getToken() {
        $.ajax({
            url: URL_WS_TOKEN,
            crossDomain : true,
            method : "POST",
            contentType : "application/json",
            data : `grant_type=password&username=${USER}&password=${PASS}`
        })
        .done(function(result) {
            setAccessTokenCon(result.access_token)
        })
        .fail(function() {
            setAccessTokenCon("")
            setErrorMessage("No se puede conectar al Call Center")
        })
    }

    useEffect(() => {
        if (accessTokenCon !== "") {
            const newConnection = hubConnection(URL_WS_HUB, {qs: "wdToken="+accessTokenCon, useDefaultPath: false});
            setConnection(newConnection);
            dispatch(setConnectionStore(newConnection))
        }
    }, [accessTokenCon])

    useEffect(() => {
        if (connection) {
            setIsLoading(true)
            // console.log(connection);
            const newHubProxy = connection.createHubProxy(HUB_NAME);
            setHubProxy(newHubProxy);
            dispatch(setHubProxyStore(newHubProxy))
        }
    }, [connection])

    useEffect(() => {
        if (hubProxy) {
            // console.log(hubProxy);
            //Event listeners server
            hubProxy.on('VL_SerNotificarEstados', function(estados) {
                // console.log(estados);
                dispatch(setEstadosStore(estados))
            });

            hubProxy.on('VL_CliLlamarTimeoutCc', function(resultado) {
                // console.log(resultado);
            });

            hubProxy.on('FIRM_CliFirmaRecibidaCc', function(connectionId, requestId, datos) {
                if (datos.Error !== 0) {
                    dispatch(setFirmaMensajeError(datos.MensajeError))
                    dispatch(setFirmaCapturadaBase64(datos.Resultado))
                } else {
                    dispatch(setFirmaMensajeError(""))
                    dispatch(setFirmaCapturadaBase64("data:image/jpeg;base64,"+datos.Resultado))
                }
            })

            hubProxy.on('FIRM_CcFirmaCli', function(resultado) {
                // console.log(resultado);
            })
            
        
            connection.start({ jsonp: false, transport: 'serverSentEvents', withCredentials: false}) //transport: 'auto'    transport: ['webSockets', 'serverSentEvents', 'longPolling'] 
            .done(function(){
                setIsLoading(false)
                // console.log('Now connected, connection ID=' + connection.id
                //             +"\ntransport = " + connection.transport.name);
                            
                hubProxy.invoke("VL_SetNombreVisible", username)
                .done(function() {
                    hubProxy.invoke("VL_SetEstado", 1)
                    .done(function() {
                        dispatch(setEstadoUserStore(1))
                    }).fail(function() {
                        dispatch(setEstadoUserStore(0)) 
                    })

                }).fail(function() { 
                    dispatch(setEstadoUserStore(0)) 
                })
                // let registroInicioSesion = 
                // {
                //     fecha: moment().format('DD/MM/YYYY HH:mm:ss'),
                //     accion: 'Inicio de sesión',
                //     clinica: '―',
                //     usuario: username,
                //     duracion: '―',
                // }
                // dispatch(setRegistro(registroInicioSesion))
                dispatch(setAccessTokenStore(localStorage.getItem("accessToken")))
                dispatch(setUsernameStore(localStorage.getItem("username")))
                navigate("/panel");
            })
            .fail(function(error){ 
                connection.connectionState.disconnected();
                setConnection(null);
                dispatch(setConnectionStore(null))
                dispatch(setHubProxyStore(null))
                setErrorMessage("No se ha podido conectar al Call Center");
                // console.log(error);
                setIsLoading(false);
                localStorage.removeItem("accessToken");
                localStorage.removeItem("username");
                dispatch(setUsernameStore(""));
                dispatch(setAccessTokenStore(""));
            });

            connection.disconnected(() => {
                // console.log("Disconnected");
                // dispatch(setEstadosStore([]));
                // navigate("/admin");
                // let registroCierreSesion = 
                // {
                //     fecha: moment().format('DD/MM/YYYY HH:mm:ss'),
                //     accion: 'Cierre de sesión',
                //     clinica: '―',
                //     usuario: username,
                //     duracion: 'Test',
                // }
                // dispatch(setRegistro(registroCierreSesion))
            })

            connection.reconnecting(() => {
                console.log("Reconnecting...");
            })

            connection.reconnected(() => {
                console.log("Reconnected!");
            })
        }
    }, [hubProxy])


    return (
        !isAuthenticated ?
        <>{errorSession !== "" ? <React.Fragment><div className="alert alert-danger alert-dismissible fade show session-error" role="alert">{errorSession}<button onClick={() => { setErrorSession(""); localStorage.removeItem("accessToken"); localStorage.removeItem("username") }} type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div></React.Fragment> : <></>}
        <div className='login-container card my-4'>
            <form className='card-body login-form' onSubmit={doLogin}>
                <div className='mb-3'>
                    <input placeholder='Usuario' className='form-control' type="text" name="username" value={username} onChange={e => setUsername(e.target.value)}/>
                </div>
                <div className='mb-3'>
                    <input placeholder='Contraseña' className='form-control' type="password" name="password" value={password} onChange={e => setPassword(e.target.value)}/>
                </div>
                {isLoading ? 
                <div className="d-flex justify-content-center"><div className="spinner-border" role="status"></div></div>
                : <></>}
                <button className='btn btn-primary mb-4'>Login</button>
                <p>{ errorMessage }</p>
            </form>
        </div>
        {/* {connection && connection.id ? <button className='btn btn-danger mb-4' onClick={() => testServer()}>Test server</button> : <></>} */}
        </>
        : <></>
    )
}

export default Login;
