import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthApiService, BackofficeApiService, OpenAPI, ClinicaDispositivo } from '../../services/openapi';
import './gestion.css';
import filtros from '../../images/filtros.svg';
import verDispositivos from '../../images/dispositivos.svg';
import tabletaFirmas from '../../images/tableta-firmas.svg';
import datafonoFisico from '../../images/datafono-fisico.svg';
import datafonoVirtual from '../../images/datafono-virtual.svg';
import eliminar from '../../images/eliminar.svg';
import activar from '../../images/activar.svg';
import close from '../../images/close.svg';
import edit from '../../images/edit.svg';
import { selectPerfil, setAccessTokenStore, setPageTitle, setScadaUrl, setUsernameStore } from '../../store/slices/userSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectConnection, selectEstados } from '../../store/slices/connectionSlice';
import ClinicaForm from './clinicaForm';

function Gestion() {

    // const [user, setUser] = useState<any>(null);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [poblacion, setPoblacion] = useState<any>("");
    const [poblaciones, setPoblaciones] = useState<any>([]);
    const [clinicas, setClinicas] = useState<any>(null);
    const [clinica, setClinica] = useState<any>(null);
    const [dispositivos, setDispositivos] = useState<any>([]);
    const [estados, setEstados] = useState<any>([]);
    const [mostrarDispositivos, setMostrarDispositivos] = useState<boolean>(false);
    const [mostrarFormularioClinica, setMostrarFormularioClinica] = useState<boolean>(false);
    const [editarFormularioClinica, setEditarFormularioClinica] = useState<boolean>(false);
    const [idDispositivoForm, setIdDispositivoForm] = useState<any>(0);
    const [dispActivoForm, setDispActivoForm] = useState<any>(false);
    const [urlDispositivoForm, setUrlDispositivoForm] = useState<any>("");
    const [tiposDispositivo, setTiposDispositivo] = useState<any>([]);
    const poblacionesSelect : any = useRef(null);

    //Pagination
    const [registrosPagina, setRegistrosPagina] = useState(50);
    const [registrosTotales, setRegistrosTotales] = useState(0);
    const [pagina, setPagina] = useState(1);
    const [paginasTotales, setPaginasTotales] = useState(0);
    const [numPaginas, setNumPaginas] = useState<any>([]);

    const tokenLocalStorage = localStorage.getItem("accessToken");
    const usernameLocalStorage = localStorage.getItem("username");
    const connection = useAppSelector(selectConnection);
    const estadosStore = useAppSelector(selectEstados);
    const perfilStore = useAppSelector(selectPerfil);
    const title = "Gestión de Clínicas y Dispositivos | PodoActiva Clinic Assistant";
    const dispatch = useAppDispatch();
    let navigate = useNavigate();


    useEffect(() => {
        if (estadosStore !== null) {
            setEstados(estadosStore);
        }
    }, [estadosStore])

    useEffect(() => {
        if (isAuthenticated && (tokenLocalStorage !== "" || tokenLocalStorage !== null)) {
            setPagina(1)
            cargaAllClinicas(pagina, registrosPagina);
        }
    }, [poblacion])

    useEffect(() => {
        if (isAuthenticated && tokenLocalStorage!=="") {
            try {
                cargaAllClinicas(pagina, registrosPagina);
            } catch (error) {
                
            }           
        }
    }, [isAuthenticated, pagina]);

    useEffect(() => {
        if (isAuthenticated && tokenLocalStorage!=="") {
            try {
                setPagina(1)
                cargaAllClinicas(pagina, registrosPagina);
            } catch (error) {
                
            }           
        }
    }, [registrosPagina]);

    useEffect(() => {
        if (clinica) {
            cargaAllDispositivosClinica();
        }
    }, [clinica])

    useEffect(() => {        
        if (paginasTotales !== 0) {
            const numPaginasArr = []
            for (let index = 0; index < paginasTotales; index++) {
                numPaginasArr.push(index+1);            
            }
            if (pagina > paginasTotales-4 && numPaginasArr.length>=4) {                
                setNumPaginas(numPaginasArr.slice(paginasTotales-4, paginasTotales))
            } else if (pagina <= 4 && numPaginasArr.length<=4) {                
                setNumPaginas(numPaginasArr.slice(0, 4))
            } else {                
                setNumPaginas(numPaginasArr.slice(pagina-1, pagina+3))
            }
        }
    }, [paginasTotales, pagina])

    useEffect(() => {
        dispatch(setPageTitle(title));
        try {
            authCheck();
        } catch (error) {
            navigate("/admin")
        }
    }, [])

    useEffect(() => {
        if (isAuthenticated && tokenLocalStorage!=="") {
            getAllPoblaciones();
            getAllTiposDispositivo();
        }   
    }, [isAuthenticated])

    useEffect(() => {
        if (usernameLocalStorage !== "") {
            dispatch(setUsernameStore(usernameLocalStorage))
        }
    }, [])


    async function getAllTiposDispositivo () {
        try {
            let response = await BackofficeApiService.backofficeApiGetDispositivos("Bearer "+tokenLocalStorage!);
            setTiposDispositivo(response)
        } catch (err: any) {        
            return null;
        }
    }

    async function getAllPoblaciones () {
        try {
            let response = await BackofficeApiService.backofficeApiGetPoblaciones("Bearer "+tokenLocalStorage!);
            setPoblaciones(response)
        } catch (err: any) {        
            return null;
        }
    }

    const cargaAllClinicas = async (pagina: any, registrosPagina: any) => {
        try {
            let response : any = await BackofficeApiService.backofficeApiGetClinicas(undefined, undefined, poblacion!, pagina, registrosPagina, "Bearer "+tokenLocalStorage!);            
            setClinicas(response.clinicas);
            setRegistrosTotales(response.registrosTotales);
            setPaginasTotales(response.paginasTotales);
            setMostrarFormularioClinica(false)
        } catch (err: any) {            
            return null;
        }
    }

    async function cargaAllDispositivosClinica () {    
        if (mostrarFormularioClinica) {
            setMostrarDispositivos(false)
        } else {
            try {
                let response = await BackofficeApiService.backofficeApiGetClinicaDispositivosClinica(clinica.id!, "Bearer "+tokenLocalStorage!);            
                // console.log(clinicaDisp.nombre);
                if (response.length === 0) {
                    setDispositivos([]);
                    setMostrarDispositivos(true); 
                    return null;
                }
                // let dispositivos = response.reduce((arr: any, element: any) => {
                //     if (element.activo) { return [element, ...arr] }
                //     return [...arr, element];
                // }, []);
                setDispositivos(response);
                setMostrarDispositivos(true); 
                
            } catch (err: any) {
                setMostrarDispositivos(true); 
                return null;
            }
        }
    }

    async function anadirDispositivoClinica () {
        if (idDispositivoForm !== 0) {
            if (idDispositivoForm !== 5 || idDispositivoForm !== 6) {
                setUrlDispositivoForm("");
            }
            try {
                let clinicaDispositivo : ClinicaDispositivo = {clinicaId: clinica.id!, dispositivoId: idDispositivoForm, url: urlDispositivoForm, activo: dispActivoForm};
                let response = await BackofficeApiService.backofficeApiNewClinicaDispositivo(clinicaDispositivo, "Bearer "+tokenLocalStorage!)
                cargaAllDispositivosClinica();
                setIdDispositivoForm(0);
                setDispActivoForm(false);
            } catch (error) {
                return null;
            }
        }
    }

    async function toggleDispActivo (dispositivo: any) {
        try {
            if (dispositivo.activo) {
                let clinicaDispositivo : ClinicaDispositivo = {id: dispositivo.id, clinicaId: dispositivo.clinicaId, dispositivoId: dispositivo.dispositivoId, url: dispositivo.url, activo: false};
                let response = await BackofficeApiService.backofficeApiSetClinicaDispositivo(dispositivo.id!, clinicaDispositivo, "Bearer "+tokenLocalStorage!)
                if (response) {
                    cargaAllDispositivosClinica();
                }
            } else {
                let clinicaDispositivo : ClinicaDispositivo = {id: dispositivo.id, clinicaId: dispositivo.clinicaId, dispositivoId: dispositivo.dispositivoId, url: dispositivo.url, activo: true};
                let response = await BackofficeApiService.backofficeApiSetClinicaDispositivo(dispositivo.id!, clinicaDispositivo, "Bearer "+tokenLocalStorage!)
                if (response) {
                    cargaAllDispositivosClinica();
                }
            }
        } catch (error) {
            return null;
        }
    }

    async function eliminarDispositivo (dispositivo: any) {        
        if (window.confirm(`¿Está seguro que quiere eliminar el dispositivo ${dispositivo.dispositivo.nombre} de ${clinica.nombre}?`)) {
            try {
                let response = await BackofficeApiService.backofficeApiDeleteClinicaDispositivo(dispositivo.id!, "Bearer "+tokenLocalStorage!)
                if (response) {
                    cargaAllDispositivosClinica();
                }
            } catch(error) {
                return null;
            }
        }
    }

    async function authCheck () {
        if (!connection) {
            localStorage.removeItem("accessToken")
            localStorage.removeItem("username")
            dispatch(setUsernameStore(""))
            dispatch(setAccessTokenStore(""))
            navigate("/admin")
            return;
        }
        if (perfilStore !== "administrador") {
            navigate("/admin")
        }
        try {
            OpenAPI.TOKEN = localStorage.getItem("accessToken")!;
            let response = await AuthApiService.authApiEchoUser();
            setIsAuthenticated(response.isAuthenticated!);
            setTimeout(() => {if (!response.isAuthenticated) {
                dispatch(setUsernameStore(""))
                dispatch(setAccessTokenStore(""))
                navigate("/admin") 
            }}, 200);
        } catch (err: any) {
            dispatch(setUsernameStore(""))
            dispatch(setAccessTokenStore(""))
            navigate("/admin");
        }
    }
    
    return (
        <>
            <div className='min-vh-100 d-flex flex-column justify-content-between container'>
                <section className='container my-2'>
                    <div className='my-3'>
                        <div className='justify-content-center align-items-center'>
                            {/* <img className='col-auto' src={citas} alt="Dispositivos" height="36"/> */}
                            <h3 className='text-center text-uppercase pt-3' style={{color:"#616161", fontWeight:"200"}}>Gestión de Clínicas y Dispositivos</h3>
                        </div>
                    </div>
                    <div className='row my-4'>
                        <div className='col-md-12'>
                            {mostrarFormularioClinica ?
                            <>{editarFormularioClinica ? 
                            //Editar clínica
                            <section id='listaDispositivosClinica' className='d-flex flex-column bg-white h-100 p-3'>
                                <div className='d-flex justify-content-end align-items-center p-3'>
                                    <img src={close} alt="Cerrar" height="15" style={{cursor:"pointer"}} onClick={() => {
                                        setMostrarFormularioClinica(false);
                                        setEditarFormularioClinica(false);
                                    }}/>
                                </div>   
                                {/* cargarClinicas={cargaAllClinicas(pagina, registrosPagina)} */}
                                <ClinicaForm clinicaFormulario={clinica} poblaciones={poblaciones} cargarClinicas={cargaAllClinicas} pagina={pagina} registrosPagina={registrosPagina}/> 
                            </section> : 
                            //Nueva clínica
                            <section id='listaDispositivosClinica' className='d-flex flex-column bg-white h-100 p-3'>
                                <div className='d-flex justify-content-end align-items-center p-3'>
                                    <img src={close} alt="Cerrar" height="15" style={{cursor:"pointer"}} onClick={() => {
                                        setMostrarFormularioClinica(false);
                                        setEditarFormularioClinica(false);
                                    }}/>
                                </div>   
                                <ClinicaForm clinicaFormulario={null} poblaciones={poblaciones} cargarClinicas={cargaAllClinicas} pagina={pagina} registrosPagina={registrosPagina}/>
                            </section>}</> :
                            <>{mostrarDispositivos ?
                            <section id='listaDispositivosClinica' className='d-flex flex-column bg-white h-100 p-3'>
                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                    <div className='d-flex align-items-center gap-3'>
                                        <button className='btn btn-primary' data-bs-toggle="collapse" data-bs-target="#nuevoDispositivo" aria-expanded="false" aria-controls="nuevoDispositivo">Nuevo dispositivo</button>
                                        {dispositivos.length === 0 ? 
                                        <p className='text-muted text-uppercase my-1'><strong>{clinica.nombre} ━ </strong>No hay dispositivos asociados</p> :
                                        <p className='text-muted text-uppercase my-1'><strong>{clinica.nombre} ━ </strong>Dispositivos</p>}
                                        
                                    </div>
                                    <img src={close} alt="Cerrar" height="15" style={{cursor:"pointer"}} onClick={() => {setMostrarDispositivos(false); setIdDispositivoForm(0); setDispositivos([]); setClinica(null);}}/>
                                </div>
                                <div className="collapse" id="nuevoDispositivo">
                                    <div className='d-flex justify-content-between gap-4 p-3 flex-wrap'>
                                        <div className='d-flex gap-3 align-items-center flex-wrap'>
                                            <div className='d-flex gap-2 flex-wrap align-items-center'>
                                                <label className="col-form-label">Dispositivo: </label>
                                                <select className="form-select w-auto" onChange={e => {setIdDispositivoForm(e.target.value)}}>
                                                    <option value="0">Seleccionar Dispositivo</option>
                                                    {tiposDispositivo && tiposDispositivo.map((tipo: any, index: number) => {
                                                        return (
                                                            <option key={index} value={tipo.id}>{tipo.nombre}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            {Number(idDispositivoForm) === 5 || Number(idDispositivoForm) === 6 ? 
                                                <div className='d-flex gap-2 flex-wrap align-items-center'>
                                                    <label className="col-form-label">URL: </label>
                                                    <input type="text" className="form-control w-auto" value={urlDispositivoForm} onChange={e => {setUrlDispositivoForm(e.target.value)}}/>
                                                </div> : <></>
                                            }
                                            <div className='d-flex gap-2 flex-wrap align-items-center'>
                                                <label className="col-form-label">Activo: </label>
                                                <input className="form-check-input m-0" type="checkbox" value={dispActivoForm} onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setDispActivoForm(true) 
                                                    } else {
                                                        setDispActivoForm(false)
                                                    } 
                                                }}/>
                                            </div>
                                        </div>
                                        <div className='d-flex h-100 align-items-end'>
                                            <button className='btn btn-primary' onClick={() => {anadirDispositivoClinica()}}>Guardar</button>
                                        </div>
                                    </div>
                                </div>
                                <table className='table table-striped mt-3'>
                                <tbody>
                                    {dispositivos && dispositivos.map((dispositivo: any, index: number) => {
                                        // console.log(dispositivo);
                                        let imageDisp;
                                        switch (dispositivo.dispositivo.id) {
                                            case 1:
                                                imageDisp = tabletaFirmas;
                                            break;
                                            case 2: 
                                                imageDisp = datafonoFisico;
                                            break;
                                            case 3: 
                                                imageDisp = datafonoVirtual;
                                            break;
                                            default:
                                                imageDisp = tabletaFirmas;
                                            break;
                                        }
                                        
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <div className='d-flex justify-content-start align-items-center gap-3'>
                                                        <div style={{width:"80px"}} className="d-flex align-items-center justify-content-center">
                                                            <img src={imageDisp} alt={dispositivo.dispositivo.nombre} height="40" className='m-1'/>
                                                        </div>
                                                        <span className='text-uppercase my-2'>{dispositivo.dispositivo.nombre}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='d-flex justify-content-center align-items-center gap-3 my-3'>
                                                        {dispositivo.activo ?
                                                            <img className='img-control disp-activo-control' src={activar} role="button" onClick={() => {toggleDispActivo(dispositivo)}} alt="Activar dispositivo" /> :
                                                            <img className='img-control disp-inactivo-control' src={activar} role="button" onClick={() => {toggleDispActivo(dispositivo)}} alt="Desactivar dispositivo" />
                                                        }
                                                        <img className='img-control' src={eliminar} alt="Eliminar dispositivo" role="button" onClick={() => {eliminarDispositivo(dispositivo)}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                </table>                                
                            </section> :
                            <section className='bg-white h-100 p-3'>
                                <div className='clinicas-filtros d-flex gap-3 justify-content-between flex-wrap align-items-center pb-3'>
                                    <div className='d-flex gap-3 flex-wrap align-items-center'>
                                        <div role="button" data-bs-toggle="collapse" data-bs-target="#filtros" aria-expanded="false" aria-controls="filtros">
                                            <img src={filtros} alt="Filtros búsqueda"/>
                                            <span className='mx-2'>Filtros</span>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-wrap gap-3 justify-content-between'>
                                        <div className='d-flex gap-3 flex-wrap align-items-center'>
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination justify-content-center">
                                                    <li className={pagina === 1 ? "page-item disabled" : "page-item"}>
                                                        <a role="button" className="page-link" aria-disabled="true" onClick={()=> {
                                                                if (pagina <= paginasTotales && pagina > 1) {
                                                                    setPagina(1)
                                                                }
                                                            }
                                                        }>&laquo;</a>
                                                    </li>                                                
                                                    <li className={pagina === 1 ? "page-item disabled" : "page-item"}>
                                                    <a role="button" className="page-link" aria-disabled="true" onClick={()=> {
                                                            if (pagina <= paginasTotales && pagina > 1) {
                                                                setPagina(pagina - 1)
                                                            }
                                                        }
                                                    }>{'<'}</a>
                                                    </li>
                                                    {
                                                        numPaginas && numPaginas.map((pag: any, index: any) => {
                                                            return (
                                                                <li key={index} className={pagina === pag ? 'page-item disabled' : 'page-item'}><a role="button" className="page-link" 
                                                                    onClick={()=> {
                                                                        setPagina(pag)
                                                                    }
                                                                }>{pag}</a></li>
                                                            )
                                                        })
                                                    }
                                                    <li className={pagina === paginasTotales ? "page-item disabled" : "page-item"}>
                                                    <a role="button" className="page-link" 
                                                        onClick={()=> {
                                                            if (pagina < paginasTotales) {
                                                                setPagina(pagina + 1)
                                                            }
                                                        }
                                                    }>{'>'}</a>
                                                    </li>
                                                    <li className={pagina === paginasTotales ? "page-item disabled" : "page-item"}>
                                                        <a role="button" className="page-link" aria-disabled="true" onClick={()=> {
                                                                if (pagina <= paginasTotales && pagina >= 1) {
                                                                    setPagina(paginasTotales)
                                                                }
                                                            }
                                                        }>&raquo;</a>
                                                    </li>    
                                                </ul>
                                            </nav>
                                        </div>
                                        <button className='btn btn-primary' onClick={() => {setMostrarFormularioClinica(true);}}>Nueva clínica</button>
                                    </div>
                                </div>
                                <div className='collapse w-100 rounded' style={{backgroundColor:"whitesmoke"}} id='filtros'>
                                    <div className='d-flex gap-3 flex-wrap align-items-center p-2'>
                                        <img height={20} src={eliminar} alt="Borrar filtros" role="button" title='Borrar filtros' onClick={() => {
                                            setPoblacion("");
                                            poblacionesSelect.current.value = ""
                                        }}/>
                                        <div className='d-flex gap-2 flex-wrap align-items-center'>
                                            <span>Población</span>
                                            <select ref={poblacionesSelect} className="form-select w-auto" id="BuscadorCiudad" name="BuscadorCiudad" onChange={e => {setPoblacion(e.target.value)}}>
                                                <option value="">Seleccionar Ciudad</option>
                                                {poblaciones && poblaciones.map((poblacion: string, index: number) => {
                                                    return (
                                                        <option key={index} value={poblacion}>{poblacion}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div className='d-flex gap-2 flex-wrap align-items-center'>
                                            <span>Núm. de registros por página</span>
                                            <select className="form-select w-auto" value={registrosPagina} onChange={e => {setRegistrosPagina(Number(e.target.value))}}>
                                                {[2, 3, 5, 10, 20, 30, 50].map(pageSize => (
                                                    <option key={pageSize} value={pageSize}>{pageSize}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='clinicas-gestion fade-in'>
                                {clinicas && clinicas.length > 0 ?
                                    <>
                                    {clinicas && clinicas.map((clinica: any, index: any) => {
                                        // console.log(clinica)
                                        return (
                                        <div key={index}>
                                            <div className="shadow-sm border card-clinica my-4 w-100">
                                                <div className="clinica-flex">
                                                    <img alt="Clínica" className='img-clinica' src="https://locations.geisinger.org/locator/clinicimages/default.jpg"/>
                                                    {/* https://www.podoactiva.com/wp-content/uploads/2021/03/clinica-Huesca-fachada.jpg */}
                                                    <div className='clinica-detalle'>
                                                        <strong><p className="text-uppercase">{clinica.nombre}</p></strong>
                                                        <h6 className=''>{clinica.direccion}</h6>
                                                        <h6 className=''>{clinica.poblacion}</h6>
                                                        <div className='d-flex justify-content-between align-items-center gap-2 flex-wrap mt-4'>
                                                            { clinica.activa ?
                                                            <span className="span-clinica-estado cli-activa">Activa</span> :
                                                            <span className="span-clinica-estado">Inactiva</span>}
                                                            {/* <button className='btn-clinica' type='button' data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>Dispositivos</button> */}
                                                            <div className='d-flex gap-3 align-items-center'>
                                                                <img role="button" height="22" src={verDispositivos} onClick={() => {setClinica(clinica)}} alt="Ver dispositivos" title="Ver dispositivos"/>
                                                                <img role="button" height="20" src={edit} onClick={() => {setMostrarFormularioClinica(true); setEditarFormularioClinica(true); setClinica(clinica);}} alt="Editar clínica" title="Editar clínica"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                    })}</> : <div className='w-100 p-3 mt-3 shadow-sm border text-center'><span>No hay resultados</span></div>
                                }
                                </div>
                                <div className='d-flex justify-content-between align-items-center gap-3'>
                                    <p className='text-center m-0'>Página {pagina} de {paginasTotales}  ━ {registrosTotales} clínicas totales</p>
                                    <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center">
                                        <li className={pagina === 1 ? "page-item disabled" : "page-item"}>
                                        <a role="button" className="page-link" aria-disabled="true" onClick={()=> {
                                            if (pagina <= paginasTotales && pagina > 1) {
                                            setPagina(1)
                                            }
                                        }}>&laquo;</a>
                                        </li>                                                
                                        <li className={pagina === 1 ? "page-item disabled" : "page-item"}>
                                        <a role="button" className="page-link" aria-disabled="true" onClick={()=> {
                                            if (pagina <= paginasTotales && pagina > 1) {
                                            setPagina(pagina - 1)
                                            }
                                        }}>{'<'}</a>
                                        </li>
                                        {
                                        numPaginas && numPaginas.map((pag: any, index: any) => {
                                            return (
                                            <li key={index} className={pagina === pag ? 'page-item disabled' : 'page-item'}>
                                                <a role="button" className="page-link" 
                                                onClick={()=> {setPagina(pag)}
                                                }>{pag}</a>
                                            </li>
                                            )
                                        })
                                        }
                                        <li className={pagina === paginasTotales ? "page-item disabled" : "page-item"}>
                                        <a role="button" className="page-link" 
                                        onClick={()=> {
                                            if (pagina < paginasTotales) {
                                            setPagina(pagina + 1)
                                            }
                                        }
                                        }>{'>'}</a>
                                        </li>
                                        <li className={pagina === paginasTotales ? "page-item disabled" : "page-item"}>
                                        <a role="button" className="page-link" aria-disabled="true" onClick={()=> {
                                            if (pagina <= paginasTotales && pagina >= 1) {
                                                setPagina(paginasTotales)
                                            }
                                            }
                                        }>&raquo;</a>
                                        </li>    
                                    </ul>
                                    </nav>
                                </div>
                            </section>}</>}
                        </div>
                    </div>
                </section>
                <div id="footer" className='container my-1'>
                    <hr/>
                    <p>Smart Clinic Assistant 2022, desarrollado por © <a href="https://wdreams.com" target="_blank">Webdreams </a></p>
                </div>
            </div>
        </> 
    )
}

export default Gestion;
