import { useStopwatch } from 'react-timer-hook';

export default function StopwatchEnLlamada({fecha, funcion, clinica, connectionId, img}) {

  let {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true });

  return (
    <>
      <small>{(minutes ? (minutes > 9 ? minutes : "0" + minutes) : "00") + ":" + (seconds > 9 ? seconds : "0" + seconds)}</small>
      <img className="img-llamada" src={img} alt="" onClick={() => {
        // reset();
        funcion(clinica, connectionId); 
      }}/>
    </>
  );
}