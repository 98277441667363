import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { BackofficeApiService, Clinica } from '../../../services/openapi';

function ClinicaForm(props: {clinicaFormulario: any, poblaciones: any, cargarClinicas: any, pagina: any, registrosPagina: any}) {

    const [nombreForm, setNombreForm] = useState<any>(props.clinicaFormulario ? props.clinicaFormulario.nombre : "");
    const [poblacionForm, setPoblacionForm] = useState<any>(props.clinicaFormulario ? props.clinicaFormulario.poblacion : "");
    const [direccionForm, setDireccionForm] = useState<any>(props.clinicaFormulario ? props.clinicaFormulario.direccion : "");
    const [clinicaActivaForm, setClinicaActivaForm] = useState<any>(props.clinicaFormulario ? props.clinicaFormulario.activa : false)
    const [errorFormulario, setErrorFormulario] = useState<any>("")
    const [botonGuardar, setBotonGuardar] = useState<any>(false)
    const tokenLocalStorage = localStorage.getItem("accessToken");
    let navigate = useNavigate();

    useEffect(() => {
        //Botón guardar activado o desactivado si hay cambios
        if (props.clinicaFormulario) {
            if (nombreForm === props.clinicaFormulario.nombre &&
            poblacionForm === props.clinicaFormulario.poblacion &&
            direccionForm === props.clinicaFormulario.direccion &&
            clinicaActivaForm === props.clinicaFormulario.activa) {
                setBotonGuardar(false)
            } else {
                setBotonGuardar(true)
            }
        }
    }, [nombreForm, poblacionForm, direccionForm, clinicaActivaForm])

    async function anadirClinica () {
        setErrorFormulario("");
        if (nombreForm !== "" && poblacionForm !== "") {
            try {
                let clinica : Clinica = {nombre: nombreForm, poblacion: poblacionForm, direccion: direccionForm!, activa: clinicaActivaForm};
                let response = await BackofficeApiService.backofficeApiNewClinica(clinica, "Bearer "+tokenLocalStorage!)
                props.cargarClinicas(props.pagina, props.registrosPagina);
                setErrorFormulario("");
            } catch (error) {
                return null;
            }
        } else {
            setErrorFormulario("Debe rellenar todos los campos que sean obligatorios")
        }
    }

    async function editarClinica () {
        setErrorFormulario("");
        if (nombreForm !== "" && poblacionForm !== "") {
            try {
                let clinica : Clinica = {nombre: nombreForm, poblacion: poblacionForm, direccion: direccionForm!, activa: clinicaActivaForm};
                let response = await BackofficeApiService.backofficeApiSetClinica(props.clinicaFormulario.id, clinica, "Bearer "+tokenLocalStorage!)
                props.cargarClinicas(props.pagina, props.registrosPagina);
                setErrorFormulario("");
            } catch (error) {
                return null; // setErrorFormulario("Ocurrió un error inesperado.")
            }
        } else {
            setErrorFormulario("Debe rellenar todos los campos que sean obligatorios")
        }
    }
    
    return (
        <>
            <div className='formularioGestion'>
                <h3 className='mb-4 pb-3 border-bottom'>{props.clinicaFormulario ? "Editar clínica" : "Nueva clínica"}</h3>
                {errorFormulario !== "" ? 
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">{errorFormulario}
                        <button onClick={() => {setErrorFormulario("") }} type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div> : <></>
                }
                <div className="mb-4">
                    <label htmlFor="nombreClinica" className="form-label required">Nombre</label>
                    <input type="text" className="form-control" id="nombreClinica" value={nombreForm || ""} onChange={e => {setNombreForm(e.target.value)}}/>
                </div>
                <div className="mb-4">
                    <label htmlFor="poblacionClinica" className="form-label required">Población</label>
                    {/* <div className="d-flex flex-wrap align-items-center" style={{gap: "15px"}}> */}
                        <select value={poblacionForm} className="form-select" id="poblacionClinica" name="poblacionClinica" onChange={e => {setPoblacionForm(e.target.value)}}>
                            <option value="">Seleccionar Ciudad</option>
                            {props.poblaciones && props.poblaciones.map((poblacion: string, index: number) => {
                                return (<option key={index} value={poblacion}>{poblacion}</option>)
                            })}
                        </select>
                        {/* <input type="text" className="form-control" style={{width:"calc(60% - 15px)"}} placeholder="Otra..." value={nombreForm || ""} onChange={e => {setNombreForm(e.target.value)}}/> */}
                    {/* </div> */}
                </div>
                <div className="mb-4">
                    <label htmlFor="direccionClinica" className="form-label">Dirección</label>
                    <input type="text" className="form-control" id="direccionClinica" value={direccionForm} onChange={e => {setDireccionForm(e.target.value)}}></input>
                </div>
                <div className="mb-4">
                    <label htmlFor="activaClinica" className="form-label" defaultChecked={true}>Activa</label>
                    {props.clinicaFormulario && clinicaActivaForm ?
                        <input type="checkbox" checked className="form-check form-check-input" id="activaClinica" onChange={(e) => {
                            if (e.target.checked) {
                                setClinicaActivaForm(true) 
                            } else {
                                setClinicaActivaForm(false)
                            } 
                        }}/> :
                        <input type="checkbox" className="form-check form-check-input" id="activaClinica" onChange={(e) => {
                            if (e.target.checked) {
                                setClinicaActivaForm(true) 
                            } else {
                                setClinicaActivaForm(false)
                            } 
                        }}/>
                    }
                </div>
                <div className='d-flex justify-content-end align-items-center gap-3 my-4'>
                    {/* <button type="submit" className="btn btn-secondary d-block" onClick={() => {
                        setMostrarFormularioClinica(false);
                        setErrorFormulario("");
                        setNombreForm("");
                        setPoblacionForm("");
                        setDireccionForm("");
                        setClinicaActivaForm(false)
                    }}>Cancelar</button> */}
                    {props.clinicaFormulario ? 
                        <>{botonGuardar ? 
                            <button type="submit" className="btn btn-primary d-block" onClick={() => {editarClinica()}}>Guardar</button> :
                            <button type="submit" disabled className="btn d-block" style={{backgroundColor:"#9BAF05", borderColor:"#9BAF05"}}>Guardar</button>
                        }</> :
                        <button type="submit" className="btn btn-primary d-block" onClick={() => {anadirClinica()}}>Guardar</button>
                    }
                </div>
            </div>  
        </> 
    )
}

export default ClinicaForm;
