import './dispositivo.css';
import tabletaFirmas from '../../../images/tableta-firmas.svg';
import datafonoFisico from '../../../images/datafono-fisico.svg';
import datafonoVirtual from '../../../images/datafono-virtual.svg';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectCallerConnectionId, selectClinicaDispositivosMostrando, selectClinicaLlamada, selectConnection, selectDestConnectionId, selectEstados, selectFechaLlamadaClinica, selectHubProxy, selectVideollamadaActiva, setCallerConnectionIdStore, setClinicaDispositivosMostrando, setClinicaLlamada, setDestConnectionIdStore, setFechaLlamadaClinica, setRegistro, setSalaStore, setVideollamadaActivaStore } from '../../../store/slices/connectionSlice';
import { selectAbrirScada, selectUsername, setAbrirScada, setEstadoUserStore, setFirmaCapturadaBase64, setFirmaMensajeError, setScadaUrl } from '../../../store/slices/userSlice';
import moment from 'moment';

function Dispositivo(props: {dispositivo: any, clinica: any}) {
  const [dispositivoInfo, setDispositivoInfo] = useState<any>("");
  const [textoVideollamada, setTextoVideollamada] = useState<any>("Iniciar videollamada");
  const connection = useAppSelector(selectConnection);
  const hubProxy = useAppSelector(selectHubProxy);
  const abrirScada = useAppSelector(selectAbrirScada);
  const videollamadaActiva = useAppSelector(selectVideollamadaActiva);
  const dispatch = useAppDispatch();
  const username = useAppSelector(selectUsername)
  const fechaLlamadaClinica = useAppSelector(selectFechaLlamadaClinica);
  const clinica = useAppSelector(selectClinicaDispositivosMostrando);
  const clinicaLlamada = useAppSelector(selectClinicaLlamada);
  const callerConnectionidStore = useAppSelector(selectCallerConnectionId);
  const destConnectionIdStore = useAppSelector(selectDestConnectionId);
  const estados = useAppSelector(selectEstados);

  function solicitarFirma(clinicaId: any, connectionId: any) {
    if (hubProxy) {
      dispatch(setFirmaCapturadaBase64(""));
      dispatch(setFirmaMensajeError(""));
      // Petición firma
      hubProxy.invoke("FIRM_CcFirmaCli", clinicaId, connectionId).done(function(resultado: any){})
    }
  }

  // useEffect(() => {
  //   console.log(estados);
    
  //   let estado = estados.find((estado: any) => Number(estado.clinicaid) === Number(props.dispositivo.clinicaId))
  //   console.log(estado.estado)
  //   // setTimeout(() => {
  //     if (estado.estado === 3) {
  //       if (videollamadaActiva && callerConnectionidStore === connection.id && destConnectionIdStore === estado.connectionId) {
  //         setTextoVideollamada("Colgar videollamada");
  //       } else {
  //         setTextoVideollamada("Iniciar disponible")
  //       }
  //     } else {
  //       setTextoVideollamada("No videollamada")
  //     }
  //   // }, 200);
  // }, [estados])

  // --------------------------- TEMPORAL -----------------------------
  useEffect(() => {

    // console.log(props.dispositivo);
    // console.log(props.clinica);
    
    let clase: any = "";
    if (props.dispositivo.activo !== true) {
      clase = "disp-inactivo"
    } else {
      clase = ""
    }

    switch (props.dispositivo.dispositivo.id) {
      case 1:
        setDispositivoInfo({image: tabletaFirmas, accion: "Solicitar firma", modal:"#firmaModal", funcion: "solicitarFirma", class: clase})
        break;
      case 2: 
        setDispositivoInfo({image: datafonoFisico, accion: "Solicitar pago", modal:"#pagoModal", funcion: null, class: clase})
        break;
      case 3: 
        setDispositivoInfo({image: datafonoVirtual, accion: "Enviar pago", modal:"#pagoModal", funcion: null, class: clase})
        break;
      case 4:
        setDispositivoInfo({image: tabletaFirmas, accion: "Imprimir documento", modal:"#imprimirModal", funcion: null, class: clase})
        break;
      case 5:
        setDispositivoInfo({image: tabletaFirmas, accion: "Acceder a cámaras", modal:null, funcion: null, class: clase})
        break;
      case 6: 
        setDispositivoInfo({image: tabletaFirmas, accion: "Servicio Scada", modal:null, funcion: "abrirScada", class: clase})
        break;
      case 7: 
        setDispositivoInfo({image: tabletaFirmas, accion: "Iniciar videollamada", modal:null, funcion: "videollamadaIni", class: clase})
        break;
    }
  }, [])

  function llamarClinica(clinica: any, connectionId: string) {
    if (hubProxy && !videollamadaActiva) {
        hubProxy.invoke("VL_CcLlamarCli", clinica.clinicaid, connectionId).done(function(resultado: any) {     
          
            console.log(resultado)
            dispatch(setSalaStore(resultado.sala));
            dispatch(setCallerConnectionIdStore(resultado.callerConnectionId));
            dispatch(setDestConnectionIdStore(resultado.destConnectionId));
            dispatch(setVideollamadaActivaStore(true));
            dispatch(setEstadoUserStore(3));
            dispatch(setFechaLlamadaClinica(moment()))
            let _clinica = {id: clinica.id, nombre: clinica.nombre, usuario: clinica.usuario, perfil: clinica.perfil, fechaCambioEstado: clinica.fechaCambioEstado, estado: 3, connectionId: clinica.connectionId, clinicaid: clinica.clinicaid, activa: clinica.activa, direccion: clinica.direccion, poblacion: clinica.poblacion}
            dispatch(setClinicaLlamada(_clinica))
            let __clinica = {id: clinica.clinicaid, nombre: clinica.usuario, usuario: clinica.usuario, perfil: clinica.perfil, estado: 3, connectionId: clinica.connectionId, clinicaid: clinica.clinicaid}
            dispatch(setClinicaDispositivosMostrando(__clinica))
            
            let registroLlamadaIni = 
              {
                fecha: moment().format('DD/MM/YYYY HH:mm:ss'),
                accion: 'Llamada iniciada',
                clinica: clinica.usuario,
                usuario: username,
                duracion: '―',
              }
            dispatch(setRegistro(registroLlamadaIni))
        })
    }
}

function colgarClinica(clinica: any, connectionId: string) {
    if (hubProxy && videollamadaActiva) {
        hubProxy.invoke("VL_CcColgarCli", clinica.clinicaid, connectionId).done(function(resultado:any){
          // console.log(resultado);
          
          dispatch(setCallerConnectionIdStore(""));
          dispatch(setDestConnectionIdStore(""));
          dispatch(setVideollamadaActivaStore(false));
          dispatch(setEstadoUserStore(1))

          let diff = moment().diff(moment(fechaLlamadaClinica))
          let minutes = moment.duration(diff).minutes()
          let seconds = moment.duration(diff).seconds()
          let registroLlamadaFin = 
          {
            fecha: moment().format('DD/MM/YYYY HH:mm:ss'),
            accion: 'Llamada finalizada',
            clinica: clinica.usuario,
            usuario: username,
            duracion: (minutes ? (minutes > 9 ? minutes : "0" + minutes) : "00") + ":" + (seconds > 9 ? seconds : "0" + seconds),
          }
          dispatch(setRegistro(registroLlamadaFin))
          dispatch(setClinicaLlamada(null))
      })
    }
}

  return (
    <>
    <div className='col' id={props.dispositivo.dispositivo.nombre}>
      <div className={dispositivoInfo.class+" card h-100 shadow-sm border"}>
        <div className='card-body'>
          <img src={dispositivoInfo.image} alt={props.dispositivo.dispositivo.nombre} height="90"/>
          <h5 className='card-title text-uppercase my-3'>{props.dispositivo.dispositivo.nombre}</h5>
          <div className='d-grid gap-2'>
            {
              dispositivoInfo.modal !== null ?
              <>
                {
                  dispositivoInfo.class === "" ?
                  <button className='btn btn-primary' data-bs-target={dispositivoInfo.modal} data-bs-toggle="modal" data-bs-backdrop="static" data-bs-keyboard="false"
                    onClick={() => {
                      // console.log(dispositivoInfo);
                      if (dispositivoInfo.funcion === "solicitarFirma") {
                        solicitarFirma(clinica.clinicaid, clinica.connectionId);
                      }
                    }
                  }>{dispositivoInfo.accion}</button> :
                  <button disabled className='btn btn-primary'>No disponible</button>
                }
              </> :
              <>
              {
                props.dispositivo.activo ?
                  <>
                    {videollamadaActiva ? 
                      <>
                        <button className='btn btn-primary' 
                          onClick={() => {
                            if (dispositivoInfo.funcion === "abrirScada") {
                              dispatch(setAbrirScada("Si"));
                              dispatch(setScadaUrl(props.dispositivo.url))
                            }
                            if (dispositivoInfo.funcion === "videollamadaIni") {
                              colgarClinica(clinica, clinica.connectionId)
                            }
                          }
                          //{(props.dispositivo.dispositivo.id === 7 && clinica.estado === 3 ? (callerConnectionidStore === connection.id && destConnectionIdStore === clinica.connectionId ? "Colgar videollamada" : "No disponible") : dispositivoInfo.accion)}
                        }>{props.dispositivo.dispositivo.id === 7 && clinica.estado === 3 && callerConnectionidStore === connection.id && destConnectionIdStore === clinicaLlamada.connectionId ? "Colgar videollamada" : dispositivoInfo.accion}
                        </button>
                      </> : 
                      <>
                        <button className='btn btn-primary' 
                          onClick={() => {
                            if (dispositivoInfo.funcion === "abrirScada") {
                              dispatch(setAbrirScada("Si"));
                              dispatch(setScadaUrl(props.dispositivo.url))
                            }
                            if (dispositivoInfo.funcion === "videollamadaIni") {
                              llamarClinica(clinica, clinica.connectionId)
                            }
                          }
                        }>{dispositivoInfo.accion}</button>
                      </>
                    }
                  </> :
                  <button disabled className='btn btn-primary'>No disponible</button>
              }
              </>
            }
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Dispositivo;
