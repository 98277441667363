import { useState, useEffect } from 'react';
import FsLightBox from 'fslightbox-react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectAbrirScada, selectScadaUrl, setAbrirScada } from '../../../store/slices/userSlice';
import close from '../../../images/close.svg';
import './scada.css'
export function Scada() {

  const scadaUrl = useAppSelector(selectScadaUrl);
  const abrirScada = useAppSelector(selectAbrirScada);
  const dispatch = useAppDispatch();
  const [toggler, setToggler] = useState(false);

  useEffect(() => {
    if (abrirScada) {
      setToggler(true)
    } else {
      setToggler(false)
    }
  }, [abrirScada])

  return (
    <div className='scada-div'>
      {/* <span className='d-flex justify-content-end align-items-center'>
          <img src={close} alt="Cerrar Scada" onClick={() => (dispatch(setAbrirScada("No")))}/>
      </span> */}
      <FsLightBox
          toggler={toggler}
          sources={[
            <div style={{width:'100vw', height:'100vh', overflow:'hidden'}} >
              <iframe className='scadaIframeLightbox' src={scadaUrl} title="Scada"></iframe>
            </div>
          ]}
          onClose={() => dispatch(setAbrirScada(false))}
      />
      {/* <iframe className='scadaIframe' src={scadaUrl} title="Scada"></iframe> */}
    </div>
  );
}
