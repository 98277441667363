/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EchoUserResponse } from '../models/EchoUserResponse';
import type { LoginRequest } from '../models/LoginRequest';
import type { LoginResponse } from '../models/LoginResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthApiService {

    /**
     * Comprueba la conectividad con el servicio
     * @returns boolean OK
     * @throws ApiError
     */
    public static authApiEchoPing(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/echoping',
        });
    }

    /**
     * Comprueba la autentificación del usuario en el servicio
     * @returns EchoUserResponse OK
     * @throws ApiError
     */
    public static authApiEchoUser(): CancelablePromise<EchoUserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/echouser',
        });
    }

    /**
     * Identifica el usuario en el servicio
     * @param login 
     * @returns LoginResponse OK
     * @throws ApiError
     */
    public static authApiLogin(
login: LoginRequest,
): CancelablePromise<LoginResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/login',
            body: login,
        });
    }

}
