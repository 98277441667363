import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface ConnectionState {
  connection: any;
  hubProxy: any,
  estados: any[],
  sala: any,
  callerConnectionId: any,
  destConnectionId: any,
  videollamadaActiva: any,
  fechaLlamadaClinica: any
  clinicaLlamada: any,
  clinicaDispositivosMostrando: any,
  registro: any[],
}

const initialState: ConnectionState = {
  connection: null,
  hubProxy: null,
  estados: [],
  sala: "",
  callerConnectionId: "",
  destConnectionId: "",
  videollamadaActiva: false,
  fechaLlamadaClinica: "",
  clinicaLlamada: null,
  clinicaDispositivosMostrando: null,
  registro: [],
};

export const connectionSlice = createSlice({
  name: 'connection',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setConnectionStore: (state, object) => {
      state.connection = object.payload;
    },
    setHubProxyStore: (state, object) => {
      state.hubProxy = object.payload;
    },
    setEstadosStore: (state, arr) => {
      state.estados = arr.payload;
    },
    setSalaStore: (state, str) => {
      state.sala = str.payload;
    },
    setCallerConnectionIdStore: (state, str) => {
      state.callerConnectionId = str.payload;
    },
    setDestConnectionIdStore: (state, str) => {
      state.destConnectionId = str.payload;
    },
    setVideollamadaActivaStore: (state, str) => {
      state.videollamadaActiva = str.payload;
    },
    setFechaLlamadaClinica: (state, str) => {
      state.fechaLlamadaClinica = str.payload;
    },
    setClinicaLlamada: (state, obj) => {
      state.clinicaLlamada = obj.payload;
    },
    setClinicaDispositivosMostrando: (state, str) => {
      state.clinicaDispositivosMostrando = str.payload;
    },
    setRegistro: (state, arr) => {
      state.registro = [arr.payload, ...state.registro];
    }
  },
});

export const { setConnectionStore, setHubProxyStore, setEstadosStore, setSalaStore, setCallerConnectionIdStore, setDestConnectionIdStore, setVideollamadaActivaStore, setFechaLlamadaClinica, setClinicaLlamada, setClinicaDispositivosMostrando, setRegistro } = connectionSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectConnection = (state: RootState) => state.connection.connection;
export const selectHubProxy = (state: RootState) => state.connection.hubProxy;
export const selectEstados = (state: RootState) => state.connection.estados;
export const selectSala = (state: RootState) => state.connection.sala;
export const selectCallerConnectionId = (state: RootState) => state.connection.callerConnectionId;
export const selectDestConnectionId = (state: RootState) => state.connection.destConnectionId;
export const selectVideollamadaActiva = (state: RootState) => state.connection.videollamadaActiva;
export const selectFechaLlamadaClinica = (state: RootState) => state.connection.fechaLlamadaClinica;
export const selectClinicaLlamada = (state: RootState) => state.connection.clinicaLlamada;
export const selectClinicaDispositivosMostrando = (state: RootState) => state.connection.clinicaDispositivosMostrando;
export const selectRegistro = (state: RootState) => state.connection.registro;


export default connectionSlice.reducer;
