import './usuarios.css';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectConnection } from '../../store/slices/connectionSlice';
import { selectPerfil, setAccessTokenStore, setPageTitle, setUsernameStore } from '../../store/slices/userSlice';
import { AuthApiService, BackofficeApiService, OpenAPI } from '../../services/openapi';
import filtros from '../../images/filtros.svg';
import eliminar from '../../images/eliminar.svg';
import edit from '../../images/edit.svg';
import close from '../../images/close.svg';
import UsuarioForm from './usuarioForm';

function GestionUsuarios() {

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [usuarios, setUsuarios] = useState<any>([]);
  const [nombreSearch, setNombreSearch] = useState<any>("");
  const [adminSearch, setAdminSearch] = useState<any>(undefined);
  const [activoSearch, setActivoSearch] = useState<any>(undefined);
  const [mostrarFormularioUsuario, setMostrarFormularioUsuario] = useState<boolean>(false);
  const [editarFormularioUsuario, setEditarFormularioUsuario] = useState<boolean>(false);
  const [usuario, setUsuario] = useState<any>(null);

  //Pagination
  const [registrosPagina, setRegistrosPagina] = useState<any>(50);
  const [registrosTotales, setRegistrosTotales] = useState<any>(0);
  const [pagina, setPagina] = useState<any>(1);
  const [paginasTotales, setPaginasTotales] = useState<any>(0);
  const [numPaginas, setNumPaginas] = useState<any>([]);

  const connection = useAppSelector(selectConnection);
  const perfilStore = useAppSelector(selectPerfil);
  const title = "Gestión de Usuarios | PodoActiva Clinic Assistant";
  const tokenLocalStorage = localStorage.getItem("accessToken");
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(setPageTitle(title));
    try {
      authCheck();
    } catch (error) {
      navigate("/admin")
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated && tokenLocalStorage!=="") {
      setPagina(1)
      cargaAllUsuarios(pagina, registrosPagina);
    }   
  }, [isAuthenticated, nombreSearch, adminSearch, activoSearch])

  useEffect(() => {
    if (isAuthenticated && tokenLocalStorage!=="") {
        try {
            cargaAllUsuarios(pagina, registrosPagina);
        } catch (error) {
            
        }           
    }
  }, [isAuthenticated, pagina]);

  useEffect(() => {
    if (isAuthenticated && tokenLocalStorage!=="") {
        try {
            setPagina(1)
            cargaAllUsuarios(pagina, registrosPagina);
        } catch (error) {
            
        }           
    }
  }, [registrosPagina]);

  const cargaAllUsuarios = async (pagina: any, registrosPagina: any) => {
    try {
      let response = await BackofficeApiService.backofficeApiGetUsuarios(nombreSearch, adminSearch, activoSearch, pagina, registrosPagina, "Bearer "+tokenLocalStorage!);
      setUsuarios(response.usuarios)
      setRegistrosTotales(response.registrosTotales);
      setPaginasTotales(response.paginasTotales);
      setMostrarFormularioUsuario(false)      
    } catch (err: any) {        
      return null;
    }
  }

  useEffect(() => {        
    if (paginasTotales !== 0) {
      const numPaginasArr = []
      for (let index = 0; index < paginasTotales; index++) {
          numPaginasArr.push(index+1);            
      }
      if (pagina > paginasTotales-4 && numPaginasArr.length>=4) {                
          setNumPaginas(numPaginasArr.slice(paginasTotales-4, paginasTotales))
      } else if (pagina <= 4 && numPaginasArr.length<=4) {                
          setNumPaginas(numPaginasArr.slice(0, 4))
      } else {                
          setNumPaginas(numPaginasArr.slice(pagina-1, pagina+3))
      }
    }
  }, [paginasTotales, pagina])


  async function authCheck () {
    if (!connection) {
        localStorage.removeItem("accessToken")
        localStorage.removeItem("username")
        dispatch(setUsernameStore(""))
        dispatch(setAccessTokenStore(""))
        navigate("/admin")
        return;
    }
    if (perfilStore !== "administrador") {
        navigate("/admin")
    }
    try {
      OpenAPI.TOKEN = localStorage.getItem("accessToken")!;
      let response = await AuthApiService.authApiEchoUser();
      setIsAuthenticated(response.isAuthenticated!);
      setTimeout(() => {if (!response.isAuthenticated) {
        dispatch(setUsernameStore(""))
        dispatch(setAccessTokenStore(""))
        navigate("/admin") 
      }}, 200);
    } catch (err: any) {
      dispatch(setUsernameStore(""))
      dispatch(setAccessTokenStore(""))
      navigate("/admin");
    }
  }

  return (
    <>
      {isAuthenticated ? 
        <div className='min-vh-100 d-flex flex-column justify-content-between container'>
          <section className='container my-2'>
            <div className='my-3'>
              <div className='justify-content-center align-items-center'>
                  {/* <img className='col-auto' src={citas} alt="Dispositivos" height="36"/> */}
                  <h3 className='text-center text-uppercase col-auto gestion-dispositivos pt-3' style={{color:"#616161", fontWeight:"200"}}>Gestión de Usuarios</h3>
              </div>
            </div>
            <div className='row my-4'>
              <div className='col-md-12'>
              {mostrarFormularioUsuario ?
                <>{editarFormularioUsuario ? 
                //Editar usuario
                <section className='d-flex flex-column bg-white h-100 p-3'>
                  <div className='d-flex justify-content-end align-items-center p-3'>
                      <img src={close} alt="Cerrar" height="15" style={{cursor:"pointer"}} onClick={() => {
                          setMostrarFormularioUsuario(false);
                          setEditarFormularioUsuario(false);
                      }}/>
                  </div>   
                  {/* cargarClinicas={cargaAllClinicas(pagina, registrosPagina)} */}
                  <UsuarioForm usuarioFormulario={usuario} cargarUsuarios={cargaAllUsuarios} pagina={pagina} registrosPagina={registrosPagina}/> 
                </section> : 
                //Nueva usuario
                <section className='d-flex flex-column bg-white h-100 p-3'>
                  <div className='d-flex justify-content-end align-items-center p-3'>
                      <img src={close} alt="Cerrar" height="15" style={{cursor:"pointer"}} onClick={() => {
                        setMostrarFormularioUsuario(false);
                        setEditarFormularioUsuario(false);
                      }}/>
                  </div>   
                  <UsuarioForm usuarioFormulario={null} cargarUsuarios={cargaAllUsuarios} pagina={pagina} registrosPagina={registrosPagina}/>
                </section>}</> :
                <section className='bg-white h-100 p-3'>
                  <div className='clinicas-filtros d-flex gap-3 justify-content-between flex-wrap align-items-center pb-3'>
                    <div className='d-flex gap-3 flex-wrap align-items-center'>
                      <div role="button" data-bs-toggle="collapse" data-bs-target="#filtros" aria-expanded="false" aria-controls="filtros">
                        <img src={filtros} alt="Filtros búsqueda"/>
                        <span className='mx-2'>Filtros</span>
                      </div>
                    </div>
                    <div className='d-flex flex-wrap gap-3 justify-content-between'>
                      <div className='d-flex gap-3 flex-wrap align-items-center'>
                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-center">
                                <li className={pagina === 1 ? "page-item disabled" : "page-item"}>
                                    <a role="button" className="page-link" aria-disabled="true" onClick={()=> {
                                            if (pagina <= paginasTotales && pagina > 1) {
                                                setPagina(1)
                                            }
                                        }
                                    }>&laquo;</a>
                                </li>                                                
                                <li className={pagina === 1 ? "page-item disabled" : "page-item"}>
                                <a role="button" className="page-link" aria-disabled="true" onClick={()=> {
                                        if (pagina <= paginasTotales && pagina > 1) {
                                            setPagina(pagina - 1)
                                        }
                                    }
                                }>{'<'}</a>
                                </li>
                                {
                                    numPaginas && numPaginas.map((pag: any, index: any) => {
                                        return (
                                            <li key={index} className={pagina === pag ? 'page-item disabled' : 'page-item'}><a role="button" className="page-link" 
                                                onClick={()=> {
                                                    setPagina(pag)
                                                }
                                            }>{pag}</a></li>
                                        )
                                    })
                                }
                                <li className={pagina === paginasTotales ? "page-item disabled" : "page-item"}>
                                <a role="button" className="page-link" 
                                    onClick={()=> {
                                        if (pagina < paginasTotales) {
                                            setPagina(pagina + 1)
                                        }
                                    }
                                }>{'>'}</a>
                                </li>
                                <li className={pagina === paginasTotales ? "page-item disabled" : "page-item"}>
                                    <a role="button" className="page-link" aria-disabled="true" onClick={()=> {
                                            if (pagina <= paginasTotales && pagina >= 1) {
                                                setPagina(paginasTotales)
                                            }
                                        }
                                    }>&raquo;</a>
                                </li>    
                            </ul>
                        </nav>
                      </div>
                      <button className='btn btn-primary' onClick={() => {setMostrarFormularioUsuario(true);}}>Nuevo usuario</button>
                    </div>
                  </div>
                  <div className='collapse w-100 rounded' style={{backgroundColor:"whitesmoke"}} id='filtros'>
                    <div className='d-flex gap-3 flex-wrap align-items-center p-2'>
                      <img height={20} src={eliminar} alt="Borrar filtros" role="button" title='Borrar filtros' onClick={() => {
                        setNombreSearch(""); 
                        setAdminSearch(undefined); 
                        setActivoSearch(undefined); 
                      }}/>
                      <div className='d-flex gap-2 flex-wrap align-items-center'>
                        <span>Nombre</span>
                        <input type="text" className="form-control w-auto" id="nombreSearch" value={nombreSearch} onChange={e => {setNombreSearch(e.target.value)}}/>
                      </div>
                      <div className='d-flex gap-2 flex-wrap align-items-center'>
                        <span>Administrador</span>
                        <input type="checkbox" className={adminSearch === undefined ? 'form-check-input m-0 check-indeterminate' : 'form-check-input m-0'} id='adminSearch' value={adminSearch} onChange={(e) => {
                          if (e.target.checked) {
                            setAdminSearch(true) 
                          } else {
                            setAdminSearch(false)
                          } 
                        }}/>
                      </div>
                      <div className='d-flex gap-2 flex-wrap align-items-center'>
                        <span>Activo</span>
                        <input type="checkbox" className={activoSearch === undefined ? 'form-check-input m-0 check-indeterminate' : 'form-check-input m-0'} id='activoSearch' value={activoSearch} onChange={(e) => {
                          if (e.target.checked) {
                            setActivoSearch(true) 
                          } else {
                            setActivoSearch(false)
                          } 
                        }}/>
                      </div>
                      <div className='d-flex gap-2 flex-wrap align-items-center'>
                        <span>Núm. de registros por página</span>
                        <select className="form-select w-auto" value={registrosPagina} onChange={e => {setRegistrosPagina(Number(e.target.value))}}>
                            {[3, 5, 10, 20, 30, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>{pageSize}</option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='mt-4 overflow-auto'>
                    <table className='table table-striped'>
                      <thead>
                        <tr>
                          <th></th>
                          <th>ID</th>
                          <th>Nombre</th>
                          <th>Email</th>
                          <th className='text-center'>Administrador</th>
                          <th className='text-center'>Activo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {usuarios && usuarios.length > 0 ?
                          <>{usuarios && usuarios.map((_usuario: any, index: number) => {
                            // .sort((u1: any, u2: any) => {
                            //   return u1.id - u2.id;
                            // })
                            return (
                              <tr key={index}>
                                <td><img role="button" height={20} src={edit} alt="Editar" title="Editar usuario" onClick={() => {setUsuario(_usuario); setMostrarFormularioUsuario(true); setEditarFormularioUsuario(true);}}/></td>
                                <td>{_usuario.id}</td>
                                <td>{_usuario.nombre}</td>
                                <td>{_usuario.email || "━"}</td>
                                <td className='text-center'>{_usuario.administrador ? <input type="checkbox" disabled checked className='form-check-input m-0'/> : <input type="checkbox" disabled className='form-check-input m-0'/>}</td>
                                <td className='text-center'>{_usuario.activo ? <input type="checkbox" disabled checked className='form-check-input m-0'/> : <input type="checkbox" disabled className='form-check-input m-0'/>}</td>
                              </tr>
                            )
                          })}</> : <tr><td className='text-center' colSpan={5}><span>No hay resultados</span></td></tr>
                        }
                      </tbody>
                    </table>
                    <div className='d-flex justify-content-between align-items-center gap-3'>
                      <p className='text-center m-0'>Página {pagina} de {paginasTotales}  ━ {registrosTotales} usuarios totales</p>
                      <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-center">
                          <li className={pagina === 1 ? "page-item disabled" : "page-item"}>
                            <a role="button" className="page-link" aria-disabled="true" onClick={()=> {
                              if (pagina <= paginasTotales && pagina > 1) {
                                setPagina(1)
                              }
                            }}>&laquo;</a>
                          </li>                                                
                          <li className={pagina === 1 ? "page-item disabled" : "page-item"}>
                            <a role="button" className="page-link" aria-disabled="true" onClick={()=> {
                              if (pagina <= paginasTotales && pagina > 1) {
                                setPagina(pagina - 1)
                              }
                            }}>{'<'}</a>
                          </li>
                          {
                            numPaginas && numPaginas.map((pag: any, index: any) => {
                              return (
                                <li key={index} className={pagina === pag ? 'page-item disabled' : 'page-item'}>
                                  <a role="button" className="page-link" 
                                    onClick={()=> {setPagina(pag)}
                                  }>{pag}</a>
                                </li>
                              )
                            })
                          }
                          <li className={pagina === paginasTotales ? "page-item disabled" : "page-item"}>
                          <a role="button" className="page-link" 
                            onClick={()=> {
                              if (pagina < paginasTotales) {
                                setPagina(pagina + 1)
                              }
                            }
                          }>{'>'}</a>
                          </li>
                          <li className={pagina === paginasTotales ? "page-item disabled" : "page-item"}>
                            <a role="button" className="page-link" aria-disabled="true" onClick={()=> {
                                if (pagina <= paginasTotales && pagina >= 1) {
                                  setPagina(paginasTotales)
                                }
                              }
                            }>&raquo;</a>
                          </li>    
                        </ul>
                      </nav>
                    </div>
                  </div>
                </section>
              }
              </div>
            </div>           
          </section> 
          <div id="footer" className='container my-1'>
              <hr/>
              <p>Smart Clinic Assistant 2022, desarrollado por © <a href="https://wdreams.com" target="_blank">Webdreams </a></p>
          </div>
        </div>
        : <></>
      }
    </> 
  );
}

export default GestionUsuarios;
