import { useAppSelector } from "../../store/hooks";
import { selectClinicaDispositivosMostrando } from "../../store/slices/connectionSlice";
import { selectFirmaCapturadaBase64, selectFirmaMensajeError } from "../../store/slices/userSlice";

function ModalFirma () {

    const firmaCapturadaBase64 = useAppSelector(selectFirmaCapturadaBase64);
    const firmaMensajeError = useAppSelector(selectFirmaMensajeError);
    const clinica = useAppSelector(selectClinicaDispositivosMostrando);

    return (
        <div className="modal fade" id="firmaModal" aria-labelledby="firmaModal" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    {
                        firmaCapturadaBase64 === "" ?
                            <><div className="modal-header">
                                <h5 className="modal-title" id="modalLabelHeader">Capturando firma</h5>
                            </div>
                            <div className="modal-body">
                                <label htmlFor="datafonoFisicoCantidad" className="form-label">Espere mientras se captura la firma del cliente, por favor.</label>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                            </div></>
                            : 
                            firmaMensajeError === "" ?
                                <><div className="modal-header">
                                    <h5 className="modal-title" id="modalLabelHeader">Firma capturada</h5>
                                </div>
                                <div className="modal-body">
                                    <label htmlFor="datafonoFisicoCantidad" className="form-label">Se ha capturado la firma del cliente correctamente.</label>
                                    <div className="d-flex justify-content-center align-items-center">
                                    <img className="img-firma" src={firmaCapturadaBase64} alt="" />
                                    </div>
                                </div>
                                <div className="modal-footer">   
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                    <a type="button" className="btn btn-primary" download={`FirmaCliente_${clinica.usuario.replace(/\s/g, "")}`} href={firmaCapturadaBase64} title="Firma">Guardar</a>
                                </div></>
                                :
                                <><div className="modal-header">
                                    <h5 className="modal-title" id="modalLabelHeader">Error al capturar la firma</h5>
                                </div>
                                <div className="modal-body">
                                    <label htmlFor="datafonoFisicoCantidad" className="form-label">{firmaMensajeError}</label>
                                </div>
                                <div className="modal-footer">
                                   <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                </div></>
                    }
                </div>
            </div>
        </div> 
    );
}

export default ModalFirma;
