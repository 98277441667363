import './header.css';
import logo from '../../images/smart-clinic-assistant.svg';
import placeholder from '../../images/placeholder.jpg';
import { useAppSelector } from '../../store/hooks';
import { selectUsername, setAccessTokenStore, setEstadoUserStore, setUsernameStore } from '../../store/slices/userSlice';
import CallCenter from '../callCenter';
import { selectClinicaLlamada, selectConnection, selectFechaLlamadaClinica, selectHubProxy, selectVideollamadaActiva, setCallerConnectionIdStore, setClinicaLlamada, setConnectionStore, setDestConnectionIdStore, setHubProxyStore, setRegistro, setVideollamadaActivaStore } from '../../store/slices/connectionSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
function Header() {

  let userImageStore = null;
  const connection = useAppSelector(selectConnection);
  const hubProxy = useAppSelector(selectHubProxy);
  const clinica = useAppSelector(selectClinicaLlamada);
  const videollamadaActiva = useAppSelector(selectVideollamadaActiva);
  const username = useAppSelector(selectUsername);
  const fechaLlamadaClinica = useAppSelector(selectFechaLlamadaClinica);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    username !== "" ?
      <><section className='shadow-sm bg-white sticky-top' style={{height:"110px"}}>
        <div className='container-fluid h-100'>
          <div id="header" className='py-3 bg-white h-100'>
            <div className='row flex-nowrap justify-content-between align-items-center h-100'>
              <div className='col-4 d-flex justify-content-start align-items-center'>
                <CallCenter/>
              </div>
              <div className='col-4 text-center'>
                <img className='img-fluid' src={logo} alt="Smart Clinic Assistant" width="200"/>
                <h1 className='text-info d-none'>Smart Clinic Assistant</h1>
              </div>
              <div className='col-4 d-flex justify-content-end align-items-center'>
                {username ? <small className='text-end me-3 text-secondary fw-bold'>{username}</small> : <></>}
                <div className='dropdown'>
                  <a href="#" className='d-block link-dark text-decoration-none dropdown-toggle' id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src={userImageStore || placeholder} alt="mdo" width="32" height="32" className='rounded-circle'/>
                  </a>
                  <ul className="dropdown-menu text-small" aria-labelledby="dropdownUser1">
                    <li><a className="dropdown-item" onClick={() => {navigate("/panel")}}>Panel</a></li>
                    <li><a className="dropdown-item" onClick={() => {navigate("/registro")}}>Registro</a></li>
                    <li><a className="dropdown-item" onClick={() => {navigate("/gestion")}}>Gestión</a></li>
                    <li><a className="dropdown-item" onClick={() => {navigate("/usuarios")}}>Usuarios</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" onClick={() => {navigate("/perfil")}}>Perfil</a></li>
                    <li><a className="dropdown-item btn-cerrarsesion" onClick={() => { 
                      // console.log(videollamadaActiva);   
                      if (hubProxy && videollamadaActiva) {
                        hubProxy.invoke("VL_CcColgarCli", clinica.clinicaid, clinica.connectionid).done(function(resultado:any){
                          dispatch(setCallerConnectionIdStore(""));
                          dispatch(setDestConnectionIdStore(""));
                          dispatch(setVideollamadaActivaStore(false));
                          dispatch(setEstadoUserStore(1))
                        })
                        let diff = moment().diff(moment(fechaLlamadaClinica))
                        let minutes = moment.duration(diff).minutes()
                        let seconds = moment.duration(diff).seconds()
                        let registroLlamadaFin = 
                          {
                            fecha: moment().format('DD/MM/YYYY HH:mm:ss'),
                            accion: 'Llamada finalizada',
                            clinica: clinica.usuario,
                            usuario: username,
                            duracion: (minutes ? (minutes > 9 ? minutes : "0" + minutes) : "00") + ":" + (seconds > 9 ? seconds : "0" + seconds),
                          }
                        dispatch(setRegistro(registroLlamadaFin))
                        dispatch(setClinicaLlamada(clinica))
                      }
                    
                      setTimeout(() => {
                        try {
                          connection.stop();
                        } catch (error) {
                          // console.log(error);
                          return;
                        }
                        localStorage.removeItem("accessToken"); 
                        localStorage.removeItem("username");
                        dispatch(setUsernameStore(""));
                        dispatch(setAccessTokenStore(""));
                        dispatch(setConnectionStore(""));
                        dispatch(setHubProxyStore(""));
                        navigate("/")
                      }, 200);
                    }}>Cerrar sesión</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <CallCenter/> */}
        </div>
      </section>
      </> :       
      
      <section className='shadow-sm bg-white' style={{height:"110px"}}>
        <div className='container-fluid h-100'>
          <div id="header" className='py-3 bg-white h-100'>
            <div className='row flex-nowrap justify-content-between align-items-center h-100'>
              <div className='col-12 text-center'>
                <img className='img-fluid' src={logo} alt="Smart Clinic Assistant" width="200"/>
                <h1 className='text-info d-none'>Smart Clinic Assistant</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}

export default Header;
