/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ArchivoPostResponse } from '../models/ArchivoPostResponse';
import type { Clinica } from '../models/Clinica';
import type { ClinicaDispositivo } from '../models/ClinicaDispositivo';
import type { ClinicasResponse } from '../models/ClinicasResponse';
import type { Dispositivo } from '../models/Dispositivo';
import type { LogsResponse } from '../models/LogsResponse';
import type { Usuario } from '../models/Usuario';
import type { UsuariosResponse } from '../models/UsuariosResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BackofficeApiService {

    /**
     * @param authorization access token
     * @returns string OK
     * @throws ApiError
     */
    public static backofficeApiGetPoblaciones(
authorization: string = 'Bearer ',
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/poblaciones',
            headers: {
                'Authorization': authorization,
            },
        });
    }

    /**
     * @param authorization access token
     * @returns Dispositivo OK
     * @throws ApiError
     */
     public static backofficeApiGetDispositivos(
authorization: string = 'Bearer ',
): CancelablePromise<Array<Dispositivo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/dispositivos',
            headers: {
                'Authorization': authorization,
            },
        });
    }

    /**
     * @param nombre 
     * @param activa 
     * @param poblacion 
     * @param pagina 
     * @param registrosPagina 
     * @param authorization access token
     * @returns ClinicasResponse OK
     * @throws ApiError
     */
     public static backofficeApiGetClinicas(
nombre?: string,
activa?: boolean,
poblacion?: string,
pagina?: number,
registrosPagina?: number,
authorization: string = 'Bearer ',
): CancelablePromise<ClinicasResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/clinicas',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'nombre': nombre,
                'activa': activa,
                'poblacion': poblacion,
                'pagina': pagina,
                'registrosPagina': registrosPagina,
            },
        });
    }
        
    /**
     * @param clinica 
     * @param authorization access token
     * @returns number OK
     * @throws ApiError
     */
    public static backofficeApiNewClinica(
clinica: Clinica,
authorization: string = 'Bearer ',
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/backoffice/clinicas',
            headers: {
                'Authorization': authorization,
            },
            body: clinica,
        });
    }
        
    /**
     * @param id 
     * @param authorization access token
     * @returns Clinica OK
     * @throws ApiError
     */
    public static backofficeApiGetClinica(
id: number,
authorization: string = 'Bearer ',
): CancelablePromise<Clinica> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/clinicas/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }
    
    /**
     * @param id 
     * @param clinica 
     * @param authorization access token
     * @returns boolean OK
     * @throws ApiError
     */
    public static backofficeApiSetClinica(
id: number,
clinica: Clinica,
authorization: string = 'Bearer ',
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/backoffice/clinicas/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            body: clinica,
        });
    }

    /**
     * @param clinicaId 
     * @param authorization access token
     * @returns ClinicaDispositivo OK
     * @throws ApiError
     */
    public static backofficeApiGetClinicaDispositivosClinica(
clinicaId: number,
authorization: string = 'Bearer ',
): CancelablePromise<Array<ClinicaDispositivo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/clinicas/{clinicaId}/dispositivos',
            path: {
                'clinicaId': clinicaId,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }

    /**
     * @param id 
     * @param authorization access token
     * @returns ClinicaDispositivo OK
     * @throws ApiError
     */
     public static backofficeApiGetClinicaDispositivo(
id: number,
authorization: string = 'Bearer ',
): CancelablePromise<ClinicaDispositivo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/clinicas/dispositivos/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }

    /**
     * @param id 
     * @param clinicaDispositivo 
     * @param authorization access token
     * @returns boolean OK
     * @throws ApiError
     */
    public static backofficeApiSetClinicaDispositivo(
id: number,
clinicaDispositivo: ClinicaDispositivo,
authorization: string = 'Bearer ',
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/backoffice/clinicas/dispositivos/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            body: clinicaDispositivo,
        });
    }
    
    /**
     * @param id 
     * @param authorization access token
     * @returns boolean OK
     * @throws ApiError
     */
    public static backofficeApiDeleteClinicaDispositivo(
id: number,
authorization: string = 'Bearer ',
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/backoffice/clinicas/dispositivos/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }
    
    /**
     * @param clinicaDispositivo 
     * @param authorization access token
     * @returns number OK
     * @throws ApiError
     */
    public static backofficeApiNewClinicaDispositivo(
clinicaDispositivo: ClinicaDispositivo,
authorization: string = 'Bearer ',
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/backoffice/clinicas/dispositivos',
            headers: {
                'Authorization': authorization,
            },
            body: clinicaDispositivo,
        });
    }

    /**
     * @param authorization access token
     * @returns ArchivoPostResponse OK
     * @throws ApiError
     */
    public static backofficeApiSetArchivo(
archivo1?: FormData,
authorization: string = 'Bearer ',
): CancelablePromise<ArchivoPostResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/backoffice/archivo',
            headers: {
                'Authorization': authorization,
                'Content-Type': 'multipart/form-data'
            },
            body: archivo1
        });
    }

    /**
     * @param inicio 
     * @param fin 
     * @param pagina 
     * @param registrosPagina 
     * @param authorization access token
     * @returns LogsResponse OK
     * @throws ApiError
     */
    public static backofficeApiGetLogs(
inicio?: string,
fin?: string,
pagina?: number,
registrosPagina?: number,
authorization: string = 'Bearer ',
): CancelablePromise<LogsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/logs',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'inicio': inicio,
                'fin': fin,
                'pagina': pagina,
                'registrosPagina': registrosPagina,
            },
        });
    }

    /**
     * @param nombre 
     * @param administrador 
     * @param activo 
     * @param pagina 
     * @param registrosPagina 
     * @param authorization access token
     * @returns UsuariosResponse OK
     * @throws ApiError
     */
    public static backofficeApiGetUsuarios(
nombre?: string,
administrador?: boolean,
activo?: boolean,
pagina?: number,
registrosPagina?: number,
authorization: string = 'Bearer ',
): CancelablePromise<UsuariosResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/usuarios',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'nombre': nombre,
                'administrador': administrador,
                'activo': activo,
                'pagina': pagina,
                'registrosPagina': registrosPagina,
            },
        });
    }
        
        /**
         * @param usuario 
         * @param password 
         * @param authorization access token
         * @returns number OK
         * @throws ApiError
         */
        public static backofficeApiNewUsuario(
usuario: Usuario,
password: string,
authorization: string = 'Bearer ',
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/backoffice/usuarios',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'password': password,
            },
            body: usuario,
        });
    }
        
        /**
         * @param id 
         * @param authorization access token
         * @returns Usuario OK
         * @throws ApiError
         */
        public static backofficeApiGetUsuario(
id: number,
authorization: string = 'Bearer ',
): CancelablePromise<Usuario> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/usuarios/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }
        
        /**
         * @param id 
         * @param usuario 
         * @param authorization access token
         * @returns boolean OK
         * @throws ApiError
         */
        public static backofficeApiSetUsuario(
id: number,
usuario: Usuario,
authorization: string = 'Bearer ',
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/backoffice/usuarios/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            body: usuario,
        });
    }
        
        /**
         * @param id 
         * @param password 
         * @param authorization access token
         * @returns boolean OK
         * @throws ApiError
         */
        public static backofficeApiSetUsuarioPassword(
id: number,
password: string,
authorization: string = 'Bearer ',
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/backoffice/usuarios/{id}/password',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'password': password,
            },
        });
    }
    
}