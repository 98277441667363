import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import './App.css';
import Header from './components/header';
import { useAppSelector } from './store/hooks';
import { selectClinicaLlamada, selectConnection, selectHubProxy, selectVideollamadaActiva } from './store/slices/connectionSlice';
import { selectUsername } from './store/slices/userSlice';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.js';

function App() {
  const connection = useAppSelector(selectConnection);
  const hubProxy = useAppSelector(selectHubProxy);
  const clinica = useAppSelector(selectClinicaLlamada);
  const videollamadaActiva = useAppSelector(selectVideollamadaActiva);
  const username = useAppSelector(selectUsername);

  const beforeUnloadConnection = () => {
      // if (hubProxy && videollamadaActiva !== "No") {
      //   hubProxy.invoke("VL_CcColgarCli", clinica.clinicaid, clinica.connectionid).done(function(resultado:any){})

      //   let registroLlamadaFin = 
      //     {
      //       fecha: moment().format('DD/MM/YYYY HH:mm:ss'),
      //       accion: 'Llamada finalizada',
      //       clinica: clinica.usuario,
      //       usuario: username,
      //       duracion: 'Test',
      //     }

      // REGISTRO -???-
      // fetch(url, {
      //    method: "POST",
      //    keepalive: true, 
      //    headers: { 
      //       "Content-Type": "application/json" 
      //    }
      // });
      // }
    try {
      connection.stop();
    } catch (error) {
      // console.log(error);
      return;
    }
  };
  
  useEffect(() => {
    if (connection !== null) {
      window.addEventListener('beforeunload', beforeUnloadConnection);
    } else {
      window.removeEventListener('beforeunload', beforeUnloadConnection)
    }
  }, [connection])

  return (
    <div className="d-flex flex-column h-100">
      <Header/>
      <Outlet/>
    </div>
  );
}

export default App;
