import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackofficeApiService } from '../../services/openapi';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectRegistro } from '../../store/slices/connectionSlice';
import { selectPerfil, setPageTitle } from '../../store/slices/userSlice';
import DatePicker, {setDefaultLocale } from  "react-datepicker";
import moment from 'moment';
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import './registro.css';
import filtros from '../../images/filtros.svg';
import eliminar from '../../images/eliminar.svg';

function Registro() {

  const title = "Registro | PodoActiva Clinic Assistant";
  const [registro, setRegistro] = useState<any>([])
  // let fechaInicio = moment().subtract(1, "days").format("DD/MM/YYYY");
  // fechaInicio.setMonth(fechaInicio.getMonth() - 1);
  const [fechaInicioSearch, setFechaInicioSearch] = useState<any>(new Date((moment().subtract(1, "months")).format("YYYY/MM/DD").toString()))
  const [fechaFinSearch, setFechaFinSearch] = useState<any>(new Date(moment().format("YYYY/MM/DD").toString()))
  const [fechaInicioSelect, setFechaInicioSelect] = useState<any>("")
  const [fechaFinSelect, setFechaFinSelect] = useState<any>("")
  const [nivelSearch, setNivelSearch] = useState<any>("")
  const dispatch = useAppDispatch();
  const perfilStore = useAppSelector(selectPerfil);
  let navigate = useNavigate();
  const tokenLocalStorage = localStorage.getItem("accessToken");

  //Pagination
  const [registrosPagina, setRegistrosPagina] = useState<any>(50);
  const [registrosTotales, setRegistrosTotales] = useState<any>(0);
  const [pagina, setPagina] = useState<any>(1);
  const [paginasTotales, setPaginasTotales] = useState<any>(0);
  const [numPaginas, setNumPaginas] = useState<any>([]);

  useEffect(() => {
    dispatch(setPageTitle(title));
    if (perfilStore !== "administrador") {
      navigate("/admin")
    }
    cargaRegistro(pagina, registrosPagina)
  }, [])

  useEffect(() => {
    if (tokenLocalStorage!=="") {
      setPagina(1)
      cargaRegistro(pagina, registrosPagina);
      console.log(fechaFinSearch)
    }   
  }, [fechaInicioSelect, fechaFinSelect, registrosPagina])

  useEffect(() => {
    if (tokenLocalStorage!=="") {
      try {
        cargaRegistro(pagina, registrosPagina);
      } catch (error) {
          
      }           
    }
  }, [pagina]);

  useEffect(() => {        
    if (paginasTotales !== 0) {
      const numPaginasArr = []
      for (let index = 0; index < paginasTotales; index++) {
          numPaginasArr.push(index+1);            
      }
      if (pagina > paginasTotales-4 && numPaginasArr.length>=4) {                
          setNumPaginas(numPaginasArr.slice(paginasTotales-4, paginasTotales))
      } else if (pagina <= 4 && numPaginasArr.length<=4) {                
          setNumPaginas(numPaginasArr.slice(0, 4))
      } else {                
          setNumPaginas(numPaginasArr.slice(pagina-1, pagina+3))
      }
    }
  }, [paginasTotales, pagina])

  useEffect(() => {
    setFechaInicioSelect((moment(fechaInicioSearch).format("YYYY/MM/DD")).toString());    
    setFechaFinSelect((moment(fechaFinSearch).add(1, "days").format("YYYY/MM/DD")).toString());
  }, [fechaInicioSearch ,fechaFinSearch])

  async function cargaRegistro(pagina: any, registrosPagina: any) {    
    let response = await BackofficeApiService.backofficeApiGetLogs(fechaInicioSelect, fechaFinSelect, pagina, registrosPagina, "Bearer "+tokenLocalStorage!);
    setRegistro(response.logs)
    setRegistrosTotales(response.registrosTotales);
    setPaginasTotales(response.paginasTotales);
  }

  return (
  <div className='min-vh-100 d-flex flex-column justify-content-between container'>
    <section className='container my-2'>
      {/* <div className='my-3'> */}
        {/* <div className='justify-content-center align-items-center'> */}
            {/* <img className='col-auto' src={citas} alt="Dispositivos" height="36"/> */}
            {/* <h3 className='text-center text-uppercase col-auto gestion-dispositivos pt-3' style={{color:"#616161", fontWeight:"200"}}>Registro</h3> */}
        {/* </div> */}
      {/* </div> */}
      <div className="row my-3"><div className="row justify-content-md-center align-items-center"></div></div>
      <div className='row my-4'>
        <div className='col-md-12'>
          <section className='bg-white h-100 p-3'>
            <div className='clinicas-filtros d-flex gap-3 justify-content-between flex-wrap align-items-center pb-3'>
              <div className='d-flex gap-3 flex-wrap align-items-center'>
                <div role="button" data-bs-toggle="collapse" data-bs-target="#filtros" aria-expanded="false" aria-controls="filtros">
                  <img src={filtros} alt="Filtros búsqueda"/>
                  <span className='mx-2'>Filtros</span>
                </div>
              </div>
              <div className='d-flex flex-wrap gap-3 justify-content-between'>
                <div className='d-flex gap-3 flex-wrap align-items-center'>
                  <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-center">
                          <li className={pagina === 1 ? "page-item disabled" : "page-item"}>
                              <a role="button" className="page-link" aria-disabled="true" onClick={()=> {
                                if (pagina <= paginasTotales && pagina > 1) {
                                    setPagina(1)
                                }
                              }}>&laquo;</a>
                          </li>                                                
                          <li className={pagina === 1 ? "page-item disabled" : "page-item"}>
                            <a role="button" className="page-link" aria-disabled="true" onClick={()=> {
                                if (pagina <= paginasTotales && pagina > 1) {
                                    setPagina(pagina - 1)
                                }
                            }}>{'<'}</a>
                          </li>
                          {
                            numPaginas && numPaginas.map((pag: any, index: any) => {
                              return (
                                <li key={index} className={pagina === pag ? 'page-item disabled' : 'page-item'}>
                                  <a role="button" className="page-link" 
                                    onClick={()=> {setPagina(pag)}
                                  }>{pag}</a>
                                </li>
                              )
                            })
                          }
                          <li className={pagina === paginasTotales ? "page-item disabled" : "page-item"}>
                          <a role="button" className="page-link" 
                              onClick={()=> {
                                  if (pagina < paginasTotales) {
                                      setPagina(pagina + 1)
                                  }
                              }
                          }>{'>'}</a>
                          </li>
                          <li className={pagina === paginasTotales ? "page-item disabled" : "page-item"}>
                              <a role="button" className="page-link" aria-disabled="true" onClick={()=> {
                                      if (pagina <= paginasTotales && pagina >= 1) {
                                          setPagina(paginasTotales)
                                      }
                                  }
                              }>&raquo;</a>
                          </li>    
                      </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div className='collapse w-100 rounded' style={{backgroundColor:"whitesmoke"}} id='filtros'>
              <div className='d-flex gap-3 flex-wrap align-items-center p-2'>
                <img height={20} src={eliminar} alt="Borrar filtros" role="button" title='Borrar filtros' onClick={() => {
                  let fechaInicio = new Date();
                  fechaInicio.setMonth(fechaInicio.getMonth() - 1);
                  setFechaInicioSearch(fechaInicio)
                  setFechaFinSearch(new Date())
                }}/>

                
                <div className='d-flex gap-2 flex-wrap align-items-center'>
                  <span>Fecha Inicio</span>
                  <DatePicker className="form-control w-auto" selected={fechaInicioSearch} dateFormat="dd/MM/yyyy" onChange={(fecha: Date) => setFechaInicioSearch(new Date(fecha))} locale={es}/>
                </div>
                <div className='d-flex gap-2 flex-wrap align-items-center'>
                  <span>Fecha Fin</span>
                  <DatePicker className="form-control w-auto" selected={fechaFinSearch} dateFormat="dd/MM/yyyy" onChange={(fecha: Date) => setFechaFinSearch(new Date(fecha))} locale={es}/>
                </div>
                {/* <div className='d-flex gap-2 flex-wrap align-items-center'>
                  <span>Nivel</span>
                  <select className="form-select w-auto" value={nivelSearch} onChange={e => {setNivelSearch(e.target.value)}}>  
                    <option key={"DEFAULT"} value="">Seleccionar Nivel</option>
                    <option key={"INFO"} value="INFO">INFO</option>
                    <option key={"DEBUG"} value="DEBUG">DEBUG</option>
                    <option key={"FATAL"} value="FATAL">FATAL</option>
                  </select>
                </div> */}
                <div className='d-flex gap-2 flex-wrap align-items-center'>
                  <span>Núm. de registros por página</span>
                  <select className="form-select w-auto" value={registrosPagina} onChange={e => {setRegistrosPagina(Number(e.target.value))}}>
                      {[3, 5, 10, 20, 30, 50].map(pageSize => (
                          <option key={pageSize} value={pageSize}>{pageSize}</option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className='mt-2 overflow-auto'>
              <table className='table'>
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Nivel</th>
                    <th>Mensaje</th>
                    <th>Detalle</th>
                  </tr>
                </thead>
                <tbody>
                  {registro && registro.length > 0 ?
                    <>{registro && registro.map((log: any, index: number) => {
                      // .sort((u1: any, u2: any) => {
                      //   return u1.id - u2.id;
                      // })
                      let fecha = moment(log.fecha).format("DD/MM/YYYY hh:mm:ss").toString();
                      let clase_row = "";
                      let clase_bg = "";
                      switch (log.nivel) {
                        case "INFO": 
                          clase_row = "table-success"
                          clase_bg = "badge bg-success"
                          break;
                        case "DEBUG":
                          clase_row = "table-warning"
                          clase_bg = "badge bg-warning"
                          break;
                        case "FATAL":
                          clase_row = "table-danger"
                          clase_bg = "badge bg-danger"
                          break;
                        default:
                          clase_row = "table-secondary"
                          clase_bg = "badge bg-secondary"
                          break;
                      }
                      return (
                        <><tr style={{verticalAlign: "middle"}} className={clase_row} key={index}>
                          <td><small>{fecha}</small></td>
                          <td><span className={clase_bg}>{log.nivel}</span></td>
                          <td>{log.mensaje}</td>
                          {log.detalle ? 
                            <td className='td-overflow' role="button" title={log.detalle} data-bs-target={'#modalLog'+index} data-bs-toggle="modal" data-bs-backdrop="static" data-bs-keyboard="false">{log.detalle}</td> : 
                            <td className='td-overflow'>{"━"}</td>
                          }
                        </tr>
                        <div className="modal fade" id={'modalLog'+index} aria-labelledby={'modalLog'+index} aria-hidden="true">
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header d-flex justify-content-between align-items-center">
                                <h5 className="modal-title" id="modalLabelHeader">Log</h5>
                                <span><small>{log.fecha}</small></span>
                              </div>
                              <div className="modal-body">
                                <span className={clase_bg}>{log.nivel}</span>
                                <p className="form-label mt-2" style={{fontWeight: "500"}}>{log.mensaje}</p>
                                <hr/>
                                <p><small style={{overflowWrap: "break-word"}}>{log.detalle}</small></p>
                              </div>
                              <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                              </div>
                            </div>
                          </div>
                        </div></> 
                      )
                    })}</> : <tr><td className='text-center' colSpan={4}><span>No hay resultados</span></td></tr>
                  }
                </tbody>
              </table>
              <div className='d-flex justify-content-between align-items-center gap-3'>
                <p className='text-center m-0'>Página {pagina} de {paginasTotales}  ━ {registrosTotales} registros totales</p>
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li className={pagina === 1 ? "page-item disabled" : "page-item"}>
                      <a role="button" className="page-link" aria-disabled="true" onClick={()=> {
                        if (pagina <= paginasTotales && pagina > 1) {
                          setPagina(1)
                        }
                      }}>&laquo;</a>
                    </li>                                                
                    <li className={pagina === 1 ? "page-item disabled" : "page-item"}>
                      <a role="button" className="page-link" aria-disabled="true" onClick={()=> {
                        if (pagina <= paginasTotales && pagina > 1) {
                          setPagina(pagina - 1)
                        }
                      }}>{'<'}</a>
                    </li>
                    {
                      numPaginas && numPaginas.map((pag: any, index: any) => {
                        return (
                          <li key={index} className={pagina === pag ? 'page-item disabled' : 'page-item'}>
                            <a role="button" className="page-link" 
                              onClick={()=> {setPagina(pag)}
                            }>{pag}</a>
                          </li>
                        )
                      })
                    }
                    <li className={pagina === paginasTotales ? "page-item disabled" : "page-item"}>
                    <a role="button" className="page-link" 
                      onClick={()=> {
                        if (pagina < paginasTotales) {
                          setPagina(pagina + 1)
                        }
                      }
                    }>{'>'}</a>
                    </li>
                    <li className={pagina === paginasTotales ? "page-item disabled" : "page-item"}>
                      <a role="button" className="page-link" aria-disabled="true" onClick={()=> {
                          if (pagina <= paginasTotales && pagina >= 1) {
                            setPagina(paginasTotales)
                          }
                        }
                      }>&raquo;</a>
                    </li>    
                  </ul>
                </nav>
              </div>
            </div>
          </section>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Registro
