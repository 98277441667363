import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthApiService, BackofficeApiService, OpenAPI } from '../../services/openapi';
import Dispositivo from './dispositivo';
import './gestiondispositivos.css';
import ModalPago from '../modals/modalpago';
import ModalFirma from '../modals/modalfirma';
import ModalImpresion from '../modals/modalimpresion';
import { selectAbrirScada, selectScadaUrl, setAbrirScada, setAccessTokenStore, setPageTitle, setScadaUrl, setUsernameStore } from '../../store/slices/userSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectClinicaDispositivosMostrando, selectConnection, selectEstados, setClinicaDispositivosMostrando, setClinicaLlamada } from '../../store/slices/connectionSlice';
import { Scada } from './scada';

function GestionDispositivos() {

    // const [user, setUser] = useState<any>(null);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [poblaciones, setPoblaciones] = useState<any>([]);
    const [poblacion, setPoblacion] = useState<string>("");
    const [clinicas, setClinicas] = useState<any>([]);
    const [dispositivos, setDispositivos] = useState<any>([]);
    const [estados, setEstados] = useState<any>([]);
    const [maquinaOnline, setMaquinaOnline] = useState<boolean>(true);
    const [mensajeClinicaEstado, setMensajeClinicaEstado] = useState<any>("Seleccione un centro para ver los dispositivos")
    const [mensajeClinica, setMensajeClinica] = useState<any>("")
    const [toggler, setToggler] = useState(false);
    const tokenLocalStorage = localStorage.getItem("accessToken");
    const usernameLocalStorage = localStorage.getItem("username");
    const connection = useAppSelector(selectConnection);
    const estadosStore = useAppSelector(selectEstados)
    const clinicaDisp = useAppSelector(selectClinicaDispositivosMostrando)
    const title = "Panel | PodoActiva Clinic Assistant";
    const dispatch = useAppDispatch();
    let navigate = useNavigate();

    const abrirScada = useAppSelector(selectAbrirScada);
    const scadaUrl = useAppSelector(selectScadaUrl);

    useEffect(() => {
        if (estadosStore !== null) {
            setEstados(estadosStore);
        }
    }, [estadosStore])

    useEffect(() => {
        if (isAuthenticated && (tokenLocalStorage !== "" || tokenLocalStorage !== null)) {
            cargaAllClinicas();
        }
    }, [poblacion])

    useEffect(() => {
        if (isAuthenticated && tokenLocalStorage && clinicaDisp !== null) {
            setDispositivos([]);
            setMensajeClinicaEstado("")
            setMensajeClinica("")
            setMaquinaOnline(false)
            estados && estados.map((estado: any) => {
                if (estado.clinicaid === clinicaDisp.id.toString()) { // && videollamada not activa
                    // dispatch(setClinicaLlamada(clinicaStored.id))
                    setMaquinaOnline(true)
                    cargaAllDispositivosClinica();                                        
                }
            })
        }
    }, [clinicaDisp])

    useEffect(() => {
        dispatch(setPageTitle(title));
        try {
            authCheck();
            if (clinicaDisp !== null) {
                cargaAllDispositivosClinica();
            }
        } catch (error) {
            navigate("/admin")
        }
    }, [])

    useEffect(() => {
        if (isAuthenticated && tokenLocalStorage!=="") {
            getAllPoblaciones();
            cargaAllClinicas();
        }   
    }, [isAuthenticated])

    useEffect(() => {
        if (usernameLocalStorage !== "") {
            dispatch(setUsernameStore(usernameLocalStorage))
        }
    }, [])

    
    async function getAllPoblaciones () {
        try {
            let response = await BackofficeApiService.backofficeApiGetPoblaciones("Bearer "+tokenLocalStorage!);
            setPoblaciones(response)
        } catch (err: any) {        
            return null;
        }
    }

    async function cargaAllClinicas () {
        try {
            let response : any = await BackofficeApiService.backofficeApiGetClinicas(undefined, true, poblacion!, 1, 1000, "Bearer "+tokenLocalStorage!);
            // console.log(response.clinicas);
            
            setClinicas(response.clinicas)
        } catch (err: any) {            
            return null;
        }
    }

    async function cargaAllDispositivosClinica () {    
        try {
            let response = await BackofficeApiService.backofficeApiGetClinicaDispositivosClinica(clinicaDisp.id!, "Bearer "+tokenLocalStorage!);            
            // console.log(clinicaDisp.nombre);
            if (response.length === 0) {
                setMensajeClinicaEstado("━ No hay dispositivos disponibles");
                setMensajeClinica(clinicaDisp.nombre)
                setDispositivos([]);
                return null;
            }
            let dispositivos = response.reduce((arr: any, element: any) => {
                if (element.activo) { return [element, ...arr] }
                return [...arr, element];
            }, []);
            setMensajeClinicaEstado("━ Dispositivos disponibles");
            setMensajeClinica(clinicaDisp.nombre)
            setDispositivos(dispositivos);
            if (abrirScada) {
                dispatch(setAbrirScada(false));
                dispatch(setScadaUrl(""));
            }
        } catch (err: any) {
            return null;
        }
    }

    async function authCheck () {
        if (!connection) {
            localStorage.removeItem("accessToken")
            localStorage.removeItem("username")
            dispatch(setUsernameStore(""))
            dispatch(setAccessTokenStore(""))
            navigate("/admin")
            return;
        }
        try {
            OpenAPI.TOKEN = localStorage.getItem("accessToken")!;
            let response = await AuthApiService.authApiEchoUser();
            
            setIsAuthenticated(response.isAuthenticated!);
            setTimeout(() => {if (!response.isAuthenticated) {
                dispatch(setUsernameStore(""))
                dispatch(setAccessTokenStore(""))
                navigate("/admin") 
            }}, 200);
        } catch (err: any) {
            dispatch(setUsernameStore(""))
            dispatch(setAccessTokenStore(""))
            navigate("/admin");
        }
    }
    
    return isAuthenticated ? 
            <>
                <div className='min-vh-100 d-flex flex-column justify-content-between container'>
                    <section className='container my-2'>
                        <div className='row my-3'>
                            <div className='row justify-content-md-center align-items-center'>
                                {/* <img className='col-auto' src={citas} alt="Dispositivos" height="36"/> */}
                                {/* <h3 className='text-center text-uppercase col-auto gestion-dispositivos' style={{color:"#6c757d"}}>Gestión de Dispositivos</h3> */}
                            </div>
                        </div>
                        <div className='row my-4'>
                            <div className='col-md-4'>
                                <div className='row g-3 mb-3'>
                                    <div className='col'>
                                        <div className='form-floating'>
                                            <select className="form-select" id="BuscadorCiudad" name="BuscadorCiudad" onChange={e => {setPoblacion(e.target.value)}}>
                                                <option value="">Seleccionar Ciudad</option>
                                                {poblaciones && poblaciones.map((poblacion: string, index: number) => {
                                                    return (
                                                        <option key={index} value={poblacion}>{poblacion}</option>
                                                    )
                                                })}
                                            </select>
                                            <label htmlFor="SeleccionarCiudad">Selecciona ciudad</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column flex-shrink-0">
                                    <ul className='nav nav-pills flex-column mb-auto'>
                                        {clinicas && clinicas.map((clinica: any, index: number) => {
                                            return (
                                                <li key={index}>
                                                    <a className={clinicaDisp && Number(clinicaDisp.id) === Number(clinica.id) ? "nav-link p-1 py-2 clinica-link-mostrando" : "CargaContenido nav-link p-1 py-2"} onClick={() => {
                                                        // setClinicaStored(clinica)
                                                        let estadoClinica : any;
                                                        estadoClinica = estados.find((estado: any) => Number(estado.clinicaid) === Number(clinica.id))
                                                        let clinicaDisp = clinicas.find((element: any) => Number(element.id) === Number(clinica.id))
                                                        let _clinica = {id: clinicaDisp.id, nombre: clinicaDisp.nombre, activa: clinicaDisp.activa, direccion: clinicaDisp.direccion, poblacion: clinicaDisp.poblacion}
                                                        // console.log({...estadoClinica, ..._clinica});
                                                        
                                                        dispatch(setClinicaDispositivosMostrando({...estadoClinica, ..._clinica}))
                                                    }} aria-current="location" id={clinica.id}>
                                                    <svg className="mx-2" width="30" height="30" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><path d="M1 22h2v-22h18v22h2v2h-22v-2zm7-3v4h3v-4h-3zm5 0v4h3v-4h-3zm-6-5h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2z"></path></svg>
                                                        <label>{clinica.nombre}</label>
                                                    </a>
                                                </li>
                                                // <div className='clinica' key={index}>
                                                //     <button onClick={() => setClinica(clinica)}>{clinica.nombre}</button>
                                                // </div>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className='col-md-8'>
                                {
                                    maquinaOnline ? 
                                    <section id='listaDispositivosClinica' className='bg-white h-100 p-3'>
                                        {
                                            // !abrirScada ? 
                                            // <>
                                            //     <Scada/>
                                            // </> :
                                            <>
                                                {/* {dispositivos.length !== 0 ? <p className="text-muted text-uppercase"><strong>{clinica.nombre}</strong> ━ Dispositivos disponibles</p> 
                                                : clinica === "" ? <p className="text-muted text-uppercase">Seleccione un centro para ver los dispositivos</p> 
                                                : <p className="text-muted text-uppercase"><strong>{clinica.nombre}</strong> ━ No hay dispositivos disponibles</p>}  */}
                                                <p className="text-muted text-uppercase"><strong>{mensajeClinica}</strong> {mensajeClinicaEstado}</p>
                                                <div className='row row-cols-1 row-cols-md-3 g-4 mb-4 text-center'>
                                                    {dispositivos && dispositivos.map((dispositivo: any, index: number) => {
                                                        let estado = estados.find((estado: any) => Number(estado.clinicaid) === Number(dispositivo.clinicaId))
                                                        return (
                                                            <Dispositivo key={index} dispositivo={dispositivo} clinica={estado}/>
                                                        )
                                                    })}
                                                </div>
                                                {abrirScada ? <Scada/> : <></>}
                                            </>
                                        }
                                    </section>
                                    : 
                                    <section id='listaDispositivosClinica' className='bg-white h-100 p-3'>
                                        <p className="text-muted text-uppercase"><strong>{clinicaDisp.nombre}</strong> ━ No disponible</p>
                                    </section>
                                }
                            </div>
                        </div>
                        {/* <button onClick={() =>navigate("/jitsi")}>Jitsi</button>
                        <button onClick={() =>navigate("/test")}>Test</button>
                        <button onClick={() =>navigate("/cliente")}>Cliente</button> */}
                    </section>
                    <div id="footer" className='container my-1'>
                        <hr/>
                        <p>Smart Clinic Assistant 2022, desarrollado por © <a href="https://wdreams.com" target="_blank">Webdreams </a></p>
                    </div>
                    {/* Ventanas modales */}
                    <ModalPago/>
                    <ModalFirma/>
                    <ModalImpresion/>
                </div>
            </> 
        : <></>
}

export default GestionDispositivos;
