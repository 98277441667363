import { JitsiMeeting } from '@jitsi/react-sdk';
import moment from 'moment';
import { useState } from 'react';
import Draggable from 'react-draggable';
import { useDispatch } from 'react-redux';
import { ResizableBox } from 'react-resizable';
import { useAppSelector } from '../../store/hooks';
import { selectClinicaLlamada, selectDestConnectionId, selectFechaLlamadaClinica, selectHubProxy, selectVideollamadaActiva, setCallerConnectionIdStore, setClinicaLlamada, setDestConnectionIdStore, setRegistro, setVideollamadaActivaStore } from '../../store/slices/connectionSlice';
import { selectUsername, setEstadoUserStore } from '../../store/slices/userSlice';
import './videollamada.css';

export function Videollamada(props: {sala: any}) {

  const [externalApi, setExternalApi] = useState<any>(null)
  const dispatch = useDispatch();
  const hubProxy = useAppSelector(selectHubProxy);
  const clinica = useAppSelector(selectClinicaLlamada);
  const videollamadaActiva = useAppSelector(selectVideollamadaActiva);
  const destConnectionId = useAppSelector(selectDestConnectionId);
  const username = useAppSelector(selectUsername);
  const fechaLlamadaClinica = useAppSelector(selectFechaLlamadaClinica);

  function colgarClinica(clinica: any, connectionId: string) {
    if (hubProxy && videollamadaActiva) {
      hubProxy.invoke("VL_CcColgarCli", clinica.clinicaid, connectionId).done(function(resultado:any){
        dispatch(setCallerConnectionIdStore(""));
        dispatch(setDestConnectionIdStore(""));
        dispatch(setVideollamadaActivaStore(false));
        dispatch(setEstadoUserStore(1))
      
        let diff = moment().diff(moment(fechaLlamadaClinica))
        let minutes = moment.duration(diff).minutes()
        let seconds = moment.duration(diff).seconds()
        let registroLlamadaFin = 
        {
          fecha: moment().format('DD/MM/YYYY HH:mm:ss'),
          accion: 'Llamada finalizada',
          clinica: clinica.usuario,
          usuario: username,
          duracion: (minutes ? (minutes > 9 ? minutes : "0" + minutes) : "00") + ":" + (seconds > 9 ? seconds : "0" + seconds),
        }
        dispatch(setRegistro(registroLlamadaFin))
        dispatch(setClinicaLlamada(null))
      })
    }
  }  

  return (
    <div>
      <Draggable bounds="body" handle=".vll-header">
        <ResizableBox width={400} height={300} minConstraints={[400, 300]} className="resizable-box">
          <div className="box no-cursor vll-container">
            <div className="vll-header d-flex justify-content-center align-items-center">
              <span>{clinica.usuario}</span>
            </div>
            <div className="vll-body">
              <div className='vll-iframe'>
                <JitsiMeeting
                  domain = { 'meeting.wdreams.com' }
                  roomName ={ props.sala }
                  configOverwrite= {{
                    notifications: [],
                    disableDeepLinking: true,
                    disableModeratorIndicator: true,
                    disableReactions: true,
                    disableReactionsModeration: true,
                    disablePolls: true,
                    disableSelfView: true,
                    disableSelfViewSettings: true,
                    disableShowMoreStats: true,
                    hideLobbyButton: true,
                    hideAddRoomButton: true,
                    requireDisplayName: false,
                    disableShortcuts: true,
                    defaultRemoteDisplayName: 'Clínica',
                    // hideDisplayName: true,
                    defaultLanguage: 'es',
                    disableProfile: true,
                    hideEmailInSettings: true,
                    readOnlyName: true,
                    toolbarButtons: ['camera', 'microphone', 'hangup', 'fullscreen'],
                    buttonsWithNotifyClick: [],
                    disableTileView: true,
                    hideConferenceSubject: true,
                    hideConferenceTimer: true,
                    hideRecordingLabel: true,
                    hideParticipantsStats: true,
                    enableDisplayNameInStats: false,
                    enableFacialRecognition: false,
                    enableNoisyMicDetection: false,
                    analytics: {
                        disabled: true,
                    },
                  }}
                  interfaceConfigOverwrite = {{
                    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                    ENFORCE_NOTIFICATION_AUTO_DISMISS_TIMEOUT: 100,
                  }}
                  getIFrameRef = {(iframeRef) => { 
                    iframeRef.style.height = '100%'; 
                    iframeRef.style.width = '100%'; 
                    iframeRef.style.overflow = 'hidden'; 
                  }}

                  onApiReady = {externalApi => {
                    // console.log('Jitsi Meet External API', externalApi);
                    setExternalApi(externalApi);
                  }}
                  onReadyToClose = {() => {
                    colgarClinica(clinica, destConnectionId)
                  }}
                  spinner = {() =>(<div className="d-flex justify-content-center align-items-center h-100" style={{backgroundColor: "#474747"}}><div className="spinner-border" role="status" style={{width:"50px", height:"50px", color:"#BCD400"}}></div></div>)}
                />
              </div>
            </div>
          </div>
        </ResizableBox>
      </Draggable>
    </div>
  );
}
