import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { BackofficeApiService, Clinica, Usuario } from '../../../services/openapi';

function UsuarioForm(props: {usuarioFormulario: any, cargarUsuarios: any, pagina: any, registrosPagina: any}) {

    const [nombreForm, setNombreForm] = useState<any>(props.usuarioFormulario ? props.usuarioFormulario.nombre : "");
    const [emailForm, setEmailForm] = useState<any>(props.usuarioFormulario ? props.usuarioFormulario.email : "");
    const [adminForm, setAdminForm] = useState<any>(props.usuarioFormulario ? props.usuarioFormulario.administrador : "");
    const [activoForm, setActivoForm] = useState<any>(props.usuarioFormulario ? props.usuarioFormulario.activo : false);
    const [passwordForm, setPasswordForm] = useState<any>("")
    const [passwordRepetirForm, setPasswordRepetirForm] = useState<any>("")
    const [errorFormulario, setErrorFormulario] = useState<any>("")
    const [botonGuardar, setBotonGuardar] = useState<any>(false)
    const tokenLocalStorage = localStorage.getItem("accessToken");
    let navigate = useNavigate();

    useEffect(() => {
        //Botón guardar activado o desactivado si hay cambios
        if (props.usuarioFormulario) {
            if (nombreForm === props.usuarioFormulario.nombre &&
            emailForm === props.usuarioFormulario.email &&
            adminForm === props.usuarioFormulario.administrador &&
            activoForm === props.usuarioFormulario.activo) {
                setBotonGuardar(false)
            } else {
                setBotonGuardar(true)
            }
        }
    }, [nombreForm, emailForm, adminForm, activoForm])

    async function anadirUsuario () {
        setErrorFormulario("");
        let emailRegex = /^\S+@\S+\.\S+$/;
        if (nombreForm !== "" && passwordForm !== "" && passwordRepetirForm !== "") {
            if (passwordForm !== passwordRepetirForm) {
                setErrorFormulario("La contraseña no coincide.")
                return;
            }            
            if (emailForm !== "") {
                if (!emailForm.toLowerCase().match(emailRegex)) {
                    setErrorFormulario("El email no es válido.")
                    return;
                }
            }
            try {
                let usuario : Usuario = {nombre: nombreForm, email: emailForm, administrador: adminForm, activo: activoForm};
                let response = await BackofficeApiService.backofficeApiNewUsuario(usuario, passwordForm, "Bearer "+tokenLocalStorage!)
                props.cargarUsuarios(props.pagina, props.registrosPagina);
                setErrorFormulario("");
            } catch (error) {
                setErrorFormulario("Ya existe un usuario con ese nombre.")
                return null;
            }
        } else {
            setErrorFormulario("Debe rellenar todos los campos que sean obligatorios")
        }
    }

    async function editarUsuario () {
        setErrorFormulario("");
        let emailRegex = /^\S+@\S+\.\S+$/;
        if (nombreForm !== "") {
            if (emailForm !== "") {
                if (!emailForm.toLowerCase().match(emailRegex)) {
                    setErrorFormulario("El email no es válido.")
                    return;
                }
            }
            try {
                let usuario : Usuario = {id: props.usuarioFormulario.id, idExterno: props.usuarioFormulario.idExterno, nombre: nombreForm, email: emailForm, administrador: adminForm, activo: activoForm};
                let response = await BackofficeApiService.backofficeApiSetUsuario(props.usuarioFormulario.id, usuario, "Bearer "+tokenLocalStorage!)
                props.cargarUsuarios(props.pagina, props.registrosPagina);
                setErrorFormulario("");
            } catch (error) {
                return null; // setErrorFormulario("Ocurrió un error inesperado.")
            }
        } else {
            setErrorFormulario("Debe rellenar todos los campos que sean obligatorios")
        }
    }
    
    return (
        <>
            <div className='formularioGestion'>
                <h3 className='mb-4 pb-3 border-bottom'>{props.usuarioFormulario ? "Editar usuario" : "Nuevo usuario"}</h3>
                {errorFormulario !== "" ? 
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">{errorFormulario}
                        <button onClick={() => {setErrorFormulario("") }} type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div> : <></>
                }
                <div className="mb-4">
                    <label htmlFor="nombreUsuario" className="form-label required">Nombre</label>
                    <input type="text" className="form-control" id="nombreUsuario" name="nombreUsuario" value={nombreForm || ""} onChange={e => {setNombreForm(e.target.value)}}/>
                </div>
                {!props.usuarioFormulario ?
                    <><div className="mb-4">
                        <label htmlFor="passwordUsuario" className="form-label required">Contraseña</label>
                        <input type="password" className="form-control" id="passwordUsuario" name="passwordUsuario" value={passwordForm} onChange={e => {setPasswordForm(e.target.value)}}/>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="passwordRepetirUsuario" className="form-label required">Repetir contraseña</label>
                        <input type="password" className="form-control" id="passwordRepetirUsuario" name="passwordRepetirUsuario" value={passwordRepetirForm} onChange={e => {setPasswordRepetirForm(e.target.value)}}/>
                    </div></> : <></>
                }
                <div className="mb-4">
                    <label htmlFor="emailUsuario" className="form-label">Email</label>
                    <input type="email" className="form-control" id="emailUsuario" name="emailUsuario" value={emailForm || ""} onChange={e => {setEmailForm(e.target.value)}}/>
                </div>
                <div className='d-flex flex-wrap gap-5'>
                    <div className="mb-4">
                        <label htmlFor="administradorUsuario" className="form-label" defaultChecked={true}>Administrador</label>
                        {props.usuarioFormulario && adminForm ?
                            <input type="checkbox" checked className="form-check form-check-input" id="administradorUsuario" onChange={(e) => {
                                if (e.target.checked) {
                                    setAdminForm(true) 
                                } else {
                                    setAdminForm(false)
                                } 
                            }}/> :
                            <input type="checkbox" className="form-check form-check-input" id="administradorUsuario" onChange={(e) => {
                                if (e.target.checked) {
                                    setAdminForm(true) 
                                } else {
                                    setAdminForm(false)
                                } 
                            }}/>
                        }
                    </div>
                    <div className="mb-4">
                        <label htmlFor="activoUsuario" className="form-label" defaultChecked={true}>Activo</label>
                        {props.usuarioFormulario && activoForm ?
                            <input type="checkbox" checked className="form-check form-check-input" id="activoUsuario" onChange={(e) => {
                                if (e.target.checked) {
                                    setActivoForm(true) 
                                } else {
                                    setActivoForm(false)
                                } 
                            }}/> :
                            <input type="checkbox" className="form-check form-check-input" id="activoUsuario" onChange={(e) => {
                                if (e.target.checked) {
                                    setActivoForm(true) 
                                } else {
                                    setActivoForm(false)
                                } 
                            }}/>
                        }
                    </div>
                </div>
                <div className='d-flex justify-content-end align-items-center gap-3 my-4'>
                    {/* <button type="submit" className="btn btn-secondary d-block" onClick={() => {
                        setMostrarFormularioClinica(false);
                        setErrorFormulario("");
                        setNombreForm("");
                        setemailForm("");
                        setadminForm("");
                        setactivoForm(false)
                    }}>Cancelar</button> */}
                    {props.usuarioFormulario ? 
                        <>{botonGuardar ? 
                            <button type="submit" className="btn btn-primary d-block" onClick={() => {editarUsuario()}}>Guardar</button> :
                            <button type="submit" disabled className="btn d-block" style={{backgroundColor:"#9BAF05", borderColor:"#9BAF05"}}>Guardar</button>
                        }</> :
                        <button type="submit" className="btn btn-primary d-block" onClick={() => {anadirUsuario()}}>Guardar</button>
                    }
                </div>
            </div>  
        </> 
    )
}

export default UsuarioForm;
