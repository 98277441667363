import moment from 'moment';
import { useStopwatch } from 'react-timer-hook';

export default function StopwatchLlamando({fecha, funcion, clinica, connectionId, img}) {

  let timeEstado = moment(fecha)
  let timeActual= moment()
  let diff = timeActual.diff(timeEstado)

  let {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true });

  minutes = moment.duration(diff).minutes()
  seconds = moment.duration(diff).seconds()

  return (
    <>
      <small>{(minutes ? (minutes > 9 ? minutes : "0" + minutes) : "00") + ":" + (seconds > 9 ? seconds : "0" + seconds)}</small>
      <img className="img-llamada" src={img} alt="" onClick={() => {
        // reset();
        funcion(clinica, connectionId); 
      }}/>
    </>
  );
}