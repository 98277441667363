import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import config from '../../services/config.json';
import { OpenAPI } from "../../services/openapi";
import { useAppSelector } from "../../store/hooks";
import { selectClinicaDispositivosMostrando, selectHubProxy } from "../../store/slices/connectionSlice";

function ModalImpresion () {

    let tokenLocalStorage = "";
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState<any>("");
    const [mensajeError, setMensajeError] = useState<any>("");
    const inputFile: any = useRef(null);
    const hubProxy = useAppSelector(selectHubProxy);
    const clinica = useAppSelector(selectClinicaDispositivosMostrando);

    useEffect(() => {
        document.getElementById('imprimirModal')!.addEventListener('hide.bs.modal', emptyFile);        
    }, [])

    async function imprimirDocumento() {
        setMensajeError("");
        setIsLoading(true)
        try {
            tokenLocalStorage = localStorage.getItem("accessToken")!;
            let formData = new FormData();
            formData.append("archivo1", file);
            const resp = await axios.post(OpenAPI.BASE+"/api/backoffice/archivo", formData, {
                headers: {
                  "content-type": "multipart/form-data",
                  "Authorization": `Bearer ${tokenLocalStorage}`,
                  "X-API-KEY": config.API_KEY,
                },
            });
            if (resp.data.error) {
                setIsLoading(false)
                setMensajeError(resp.data.mensajeError)
            } else {
                //LLAMADA A SIGNALR PARA IMPRIMIR
                // console.log(resp.data.nombreArchivos[0]);
                ccImprimirCli(clinica.id, clinica.connectionId, resp.data.nombreArchivos[0])
            }
          
        } catch (error) {
            setIsLoading(false)
            setMensajeError("Ocurrió un error inesperado.")
        }
    }

    function fileChange (event: any) {
        const file = event.target.files && event.target.files[0];
        if (!file) {
          return;
        }
        setMensajeError("");
        setFile(file)        
    };

    function emptyFile() {
        inputFile.current.value = null;
        setFile("")
        setMensajeError("")        
    }

    function ccImprimirCli(clinicaId: string, connectionId: string, archivo: string) {
        if (hubProxy) {
            hubProxy.invoke("IMPR_CcImprimirCli", clinicaId, connectionId, archivo).done(function(resultado: any) {
                if (resultado.error) {
                    let msjError = "";
                    if (resultado.mensajeError !== null) {msjError = resultado.mensajeError}
                    setIsLoading(false)
                    setMensajeError("Ocurrió un error. " + msjError)
                } else {
                    setIsLoading(false)
                    setMensajeError("El documento se ha subido correctamente.")
                }
            })
        }
    }

    return (
        <div className="modal fade" id="imprimirModal" tabIndex={-1} aria-labelledby="imprimirModal" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalLabelHeader">Imprimir documento</h5>
                    </div>
                    <div className="modal-body">
                        
                        <form encType="multipart/form-data" method="post">
                            <div className="mb-3 d-flex gap-2">
                                {isLoading ? <div className="spinner-border" style={{width:"30px", height:"30px", color:"#BCD400"}}></div> : <></>}
                                <input ref={inputFile} id="ficheroorigen" name="ficheroorigen" type="file" accept="application/pdf" onChange={fileChange}/> 
                            </div>
                        </form>
                        <p>{mensajeError}</p>                
                        {/* <div>
                            <input id="ficheroimprimir" value="Imprimir documento" type="submit" className="Impresora btn btn-primary"/>  
                        </div> */}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                        <button type="button" className="btn btn-primary" onClick={(e) => {
                            e.preventDefault(); 
                            imprimirDocumento();
                        }}>Subir</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalImpresion;
