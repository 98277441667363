import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { selectCallerConnectionId, selectClinicaDispositivosMostrando, selectClinicaLlamada, selectConnection, selectDestConnectionId, selectEstados, selectFechaLlamadaClinica, selectHubProxy, selectSala, selectVideollamadaActiva, setCallerConnectionIdStore, setClinicaDispositivosMostrando, setClinicaLlamada, setDestConnectionIdStore, setFechaLlamadaClinica, setRegistro, setSalaStore, setVideollamadaActivaStore } from '../../store/slices/connectionSlice';
import './callcenter.css'
import aceptar from '../../images/aceptar.svg';
import llamar from '../../images/llamar.svg';
import colgar from '../../images/colgar.svg';
import telefonoLLamadaEntrante from '../../images/telefonoLlamadaEntrante.svg';
import telefono from '../../images/telefono.svg';
import connected from '../../images/connected.svg';
import disconnected from '../../images/disconnected.svg';
import oncall from '../../images/oncall.svg';
import useWindowFocus from '../hooks/windowFocus';
import { Videollamada } from '../videollamada';
import { selectEstadoUser, selectpageTitle, selectUsername, setEstadoUserStore } from '../../store/slices/userSlice';
import moment from 'moment';
import StopwatchLlamando from '../hooks/StopwatchLlamando';
import StopwatchEnLlamada from '../hooks/StopwatchEnLlamada';

function CallCenter() {

    const estados = useAppSelector(selectEstados);
    const [estadosClinica, setEstadosClinica] = useState<any>([])
    const [estadosBackoffice, setEstadosBackoffice] = useState<any>([])
    const [llamadasEntrantes, setLlamadasEntrantes] = useState<number>(0)
    const [sala, setSala] = useState<string>("")
    const dispatch = useAppDispatch();
    const hubProxy = useAppSelector(selectHubProxy);
    const connection = useAppSelector(selectConnection);
    const pageTitle = useAppSelector(selectpageTitle);
    const username = useAppSelector(selectUsername);
    const estadoUser = useAppSelector(selectEstadoUser);
    const salaStore = useAppSelector(selectSala);
    const callerConnectionidStore = useAppSelector(selectCallerConnectionId);
    const destConnectionIdStore = useAppSelector(selectDestConnectionId);
    const videollamadaActivaStore = useAppSelector(selectVideollamadaActiva);
    const fechaLlamadaClinica = useAppSelector(selectFechaLlamadaClinica);
    const clinicaDispMostrando = useAppSelector(selectClinicaDispositivosMostrando);
    const dropdownCallCenter: any = useRef(null);
    let navigate = useNavigate();
    const windowFocused = useWindowFocus();

    const values: any = {
        0: "Desconectado",
        1: "Disponible",
        2: "Llamando",
        3: "En Llamada"
    }

    const classes: any = {
        0: "badge rounded-pill bg-secondary badge-llamada",
        1: "badge rounded-pill bg-success badge-llamada",
        2: "badge rounded-pill bg-warning text-dark badge-llamada",
        3: "badge rounded-pill bg-danger badge-llamada"
    }

    let estClinica: any[] = [];

    useEffect(() => {        
        if (estados !== null) {     
            
            let llaEntrantes = 0;
            estados && estados.map((estado: any) =>{
                if (estado.estado === 2) {
                    llaEntrantes++;
                }
            });
            setLlamadasEntrantes(llaEntrantes);

            
            estClinica = [];
            estados && estados.map((estado: any) =>{
                if (estado.clinicaid !== "") {
                    estClinica.push(estado);
                }
            });
            const estCli = estClinica.sort((est1, est2) => {
                return est1.estado - est2.estado;
            }).reverse();
            setEstadosClinica(estCli);

            
            let estBackoffice: any[] = [];
            estados && estados.map((estado: any) =>{
                if (estado.clinicaid === "" && estado.connectionId !== connection.id) {
                    estBackoffice.push(estado);
                }
            });
            const estBack = estBackoffice.sort((est1, est2) => {
                return est1.estado - est2.estado;
            }).reverse();
            setEstadosBackoffice(estBack);

            // const estBack = estBackoffice.reduce((arr, est) => {
            //     if (est.estado === 3) {
            //       return [est, ...arr];
            //     }
            //     return [...arr, est];
            //   }, []);
            setEstadosBackoffice(estBack);
        }
    }, [estados])
    
    useEffect(() => {
        if (windowFocused || !windowFocused) {
            if (llamadasEntrantes === 0) {
                document.title = pageTitle;
            } else {
                document.title = "("+llamadasEntrantes+") " + pageTitle;
            }
        }
    }, [llamadasEntrantes, pageTitle])

    function llamarClinica(clinica: any, connectionId: string) {                
        if (hubProxy && !videollamadaActivaStore) {
            hubProxy.invoke("VL_CcLlamarCli", clinica.clinicaid, connectionId).done(function(resultado: any) {
                // console.log(resultado);
                
                dispatch(setSalaStore(resultado.sala));
                dispatch(setCallerConnectionIdStore(resultado.callerConnectionId));
                dispatch(setDestConnectionIdStore(resultado.destConnectionId));
                dispatch(setVideollamadaActivaStore(true));
                dispatch(setEstadoUserStore(3))
                dispatch(setClinicaLlamada(clinica))
                dispatch(setFechaLlamadaClinica(moment()))
                //trigger                
                let _clinica = {id: clinica.clinicaid, nombre: clinica.usuario, usuario: clinica.usuario, perfil: clinica.perfil, estado: 3, connectionId: clinica.connectionId, clinicaid: clinica.clinicaid}
                dispatch(setClinicaDispositivosMostrando(_clinica))
                let registroLlamadaIni = 
                {
                  fecha: moment().format('DD/MM/YYYY HH:mm:ss'),
                  accion: 'Llamada iniciada',
                  clinica: clinica.usuario,
                  usuario: username,
                  duracion: '―',
                }                
                dispatch(setRegistro(registroLlamadaIni))
                // document.getElementsByClassName("dropdown-toggle").dropdown("toggle");           
            })
        }
    }

    function colgarClinica(clinica: any, connectionId: string) {
        if (hubProxy && videollamadaActivaStore) {
            hubProxy.invoke("VL_CcColgarCli", clinica.clinicaid, connectionId).done(function(resultado:any){                
                dispatch(setCallerConnectionIdStore(""));
                dispatch(setDestConnectionIdStore(""));
                dispatch(setVideollamadaActivaStore(false));
                dispatch(setEstadoUserStore(1))

                let diff = moment().diff(moment(fechaLlamadaClinica))
                let minutes = moment.duration(diff).minutes()
                let seconds = moment.duration(diff).seconds()
                let registroLlamadaFin = 
                {
                  fecha: moment().format('DD/MM/YYYY HH:mm:ss'),
                  accion: 'Llamada finalizada',
                  clinica: clinica.usuario,
                  usuario: username,
                  duracion: (minutes ? (minutes > 9 ? minutes : "0" + minutes) : "00") + ":" + (seconds > 9 ? seconds : "0" + seconds),
                }
                dispatch(setRegistro(registroLlamadaFin))
                dispatch(setClinicaLlamada(null))
            })
        }
    }

    function conectarUsuario() {
        if (estadoUser === 0) {
            hubProxy.invoke("VL_SetEstado", 1).done(function(resultado: any){                
                dispatch(setEstadoUserStore(1))
            }).fail(function() { })
        }
    }

    function desconectarUsuario() {
        if (estadoUser !== 0 && estadoUser !== 3) {
            hubProxy.invoke("VL_SetEstado", 0).done(function(resultado: any){
                dispatch(setEstadoUserStore(0))
            }).fail(function() { })
        }
    }

    return (
        <>
        <div className="dropdown">
            <button className={estados.length !== 0 ? "btn dropdown-toggle btn-telefono" : "btn btn-telefono"} type="button" id="dropdownCallCenter" data-bs-toggle={estados.length !== 0 ? "dropdown" : ""} data-bs-auto-close="false" aria-expanded="false">
                {llamadasEntrantes > 0 && estadoUser === 1 ? 
                    <img className='telefono-icono' src={telefonoLLamadaEntrante} alt="Llamadas"/> :
                    <img className='telefono-icono' src={telefono} alt="Llamadas"/>
                }
            </button>
            { estados.length !== 0 ?
            <ul className="dropdown-menu dropdown-callcenter" id='dropdownCallCenter' ref={dropdownCallCenter} aria-labelledby="dropdownCallCenter">
                <li>
                    <div className="d-flex flex-wrap justify-content-start align-items-center header-username">
                        {estadoUser === 0 ?
                            <img className="img-estado-header" src={disconnected} alt="" /> : <></>
                        }
                        {estadoUser === 1 ?
                            <img className="img-estado-header" src={connected} alt="" /> : <></>
                        }
                        {estadoUser === 2 || estadoUser === 3 ?
                            <img className="img-estado-header" src={oncall} alt="" /> : <></>
                        }
                        <a className="dropdown-item-text text-username-estado" data-bs-toggle="collapse" href="#collapseEstadosUser" role="button" aria-expanded="false" aria-controls="collapseEstadosUser">{username}</a>
                    </div>
                    <div className="collapse" id="collapseEstadosUser">
                        <span onClick={() => conectarUsuario()} className="dropdown-item d-flex flex-wrap justify-content-start align-items-center">
                            <img className="img-estado" src={connected} alt="" />
                            <small>{values[1]}</small>
                        </span>
                        <span onClick={() => desconectarUsuario()} className="dropdown-item d-flex flex-wrap justify-content-start align-items-center">
                            <img className="img-estado" src={disconnected} alt="" />
                            <small>{values[0]}</small>
                        </span>
                        <br/>
                    </div>
                </li>
                { estadoUser !== 0 ?
                    estadosClinica && estadosClinica.map((clinica: any, index: any) => {
                        return (<React.Fragment key={index}>
                            <li><span className="dropdown-item-text">
                                {index===0 ? <><div><h5>Clínicas</h5></div><hr className="dropdown-divider"/></> : <></>}
                                <div className='d-flex flex-wrap justify-content-between align-items-center'>
                                    <span className="w-100 pb-1">{clinica.usuario}</span>
                                    <div className="d-flex flex-row-reverse align-items-center">
                                        {clinica.estado === 2 && estadoUser !== 3 ? 
                                            <>
                                                <StopwatchLlamando fecha={clinica.fechaCambioEstado} funcion={llamarClinica} clinica={clinica} connectionId={clinica.connectionId} img={aceptar}></StopwatchLlamando>
                                            </> : <></>
                                        }
                                        {clinica.estado === 3 && callerConnectionidStore === connection.id && destConnectionIdStore === clinica.connectionId ?
                                            <>
                                                <StopwatchEnLlamada fecha={clinica.fechaCambioEstado} funcion={colgarClinica} clinica={clinica} connectionId={clinica.connectionId} img={colgar}></StopwatchEnLlamada>
                                            </> : <></>
                                        }
                                        {clinica.estado === 1 && !videollamadaActivaStore ? 
                                            <img className="img-llamada" src={llamar} alt="" onClick={() => {llamarClinica(clinica, clinica.connectionId)}}/> : <></>
                                        }
                                        <span className={classes![clinica.estado]}>{values![clinica.estado]}</span>
                                    </div>
                                </div>
                                {/* <span><small>{estado.nombre}</small></span> */}
                                <hr className="dropdown-divider"/>
                            </span></li>
                            {index+1===estadosClinica.length ? <br/> : <></>}
                        </React.Fragment>)
                    }) : <></>
                }
                {estadosBackoffice && estadosBackoffice.map((estado: any, index: any) => {
                    if (estado.perfil === "Backoffice") {
                        return (<React.Fragment key={index}>
                            <li><span className="dropdown-item-text">
                                {index===0 ? <><div><h5>Usuarios</h5></div><hr className="dropdown-divider"/></> : <></>}
                                <div className='d-flex flex-wrap justify-content-between align-items-center'>
                                    <span>{estado.usuario}</span>
                                    <span className={classes![estado.estado]}>{values![estado.estado]}</span>
                                </div>
                                <span><small>{estado.nombre}</small></span>
                            </span></li>
                        </React.Fragment>)
                    }
                })}
            </ul> : <></> }
        </div>
        { videollamadaActivaStore ? <Videollamada sala={salaStore}/> : <></> }
        {
            llamadasEntrantes > 0 ?
                estados && estados.map((estado: any, index: any) =>{
                    if (estado.estado === 2 && estadoUser !== 0 && estadoUser !== 3) {
                        return (
                            <div key={index} className="d-flex flex-column justify-content-center align-items-left toast-llamada"><h6>Llamada entrante</h6><small>{estado.usuario}</small></div>
                        )
                    }
                })
                :
                <></>
        }
        {/* <div className="d-flex flex-column justify-content-center align-items-left toast-llamada"><h6>Llamada entrante</h6><small>Clínica WD</small></div> */}
        </>
    );
}

export default CallCenter;
