import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectPerfil, setPageTitle } from '../../store/slices/userSlice';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import placeholder from '../../images/placeholder.jpg';
import './perfil.css';
import { AuthApiService, BackofficeApiService, Usuario } from '../../services/openapi';

function Perfil() {

  const title = "Perfil | PodoActiva Clinic Assistant";
  const tokenLocalStorage = localStorage.getItem("accessToken");
  const [errorFormulario, setErrorFormulario] = useState<any>("")
  const [mensajeCorrecto, setMensajeCorrecto] = useState<any>("")
  const [emailForm, setEmailForm] = useState<any>("");
  const [passwordForm, setPasswordForm] = useState<any>("")
  const [passwordRepetirForm, setPasswordRepetirForm] = useState<any>("")
  const [passwordNuevaForm, setPasswordNuevaForm] = useState<any>("")
  const [idUser, setIdUser] = useState<any>("")
  const [user, setUser] = useState<any>("")
  const perfilStore = useAppSelector(selectPerfil);
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  

  useEffect(() => {
    dispatch(setPageTitle(title));
    if (perfilStore !== "administrador") {
      navigate("/admin")
    }
    parseJwt(tokenLocalStorage!);
  }, [])

  useEffect(() => {
    if (idUser !== "") {
      getUser();
    }
  }, [idUser])

  
  function parseJwt(token: string) {
    try {
      setIdUser(JSON.parse(atob(token.split('.')[1]))['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid'])
    } catch (e) {
      return null;
    }
  };

  async function getUser() {
    let response = await BackofficeApiService.backofficeApiGetUsuario(idUser, "Bearer "+tokenLocalStorage!)
    setUser(response)
    console.log(response);
    
  }

  async function cambiarPassword () {
    setErrorFormulario("");
    setMensajeCorrecto("");
    if (passwordForm !== "" && passwordNuevaForm !== "" && passwordRepetirForm !== "") {
      if (passwordNuevaForm !== passwordRepetirForm) {
        setErrorFormulario("La contraseña nueva no coincide.")
        return;
      } 
      try {
        let response = await AuthApiService.authApiLogin({username: user.nombre, password: passwordForm, idExterno: 0, extendedExpiry: true});
        if (response) {
          await BackofficeApiService.backofficeApiSetUsuarioPassword(user.id, passwordNuevaForm, "Bearer "+tokenLocalStorage!)
          setErrorFormulario("");
          setPasswordForm("");
          setPasswordNuevaForm("");
          setPasswordRepetirForm("");
          setMensajeCorrecto("Contraseña actualizada correctamente.")
        }        
      } catch (e) {
        setErrorFormulario("Contraseña actual incorrecta.")
        return null;
      }
    } else {
      setErrorFormulario("Debe rellenar todos los campos que sean obligatorios")
    }
  }

  async function cambiarEmail () {
    setErrorFormulario("");
    setMensajeCorrecto("");
    let emailRegex = /^\S+@\S+\.\S+$/;
    console.log(emailForm);
    
    if (emailForm === "" || !(emailForm.toLowerCase().match(emailRegex))) {
      setErrorFormulario("El email no es válido.")
      return;
    }
    try {
      let usuario : Usuario = {id: user.id, idExterno: user.idExterno, nombre: user.nombre, email: emailForm, administrador: user.administrador, activo: user.activo};
      let response = await BackofficeApiService.backofficeApiSetUsuario(user.id, usuario, "Bearer "+tokenLocalStorage!)
      setErrorFormulario("");
      setEmailForm("");
      setMensajeCorrecto("Email actualizado correctamente.");
    } catch (error) {
      setErrorFormulario("Ocurrió un error")
      return null;
    }
  }

  return (
    <>
      <div className="container my-5 bg-white d-flex flex-column align-items-center p-4" style={{ height:"80%", minHeight: "400px"}}>
        <div className="d-flex flex-wrap justify-content-start align-items-center gap-4 w-100" style={{height:"max-content"}}>
          <Tabs className="w-100 d-flex justify-content-start flex-wrap" style={{gap: "20px"}}>
            <TabList className="d-flex flex-column m-0 p-2 border rounded card-list-menu">
              <li className="react-tabs__tab" style={{paddingTop:"8px", paddingBottom:"8px", backgroundColor:"rgb(219, 219, 219)", fontWeight:"600"}}>Perfil</li>
              <Tab>Mis datos</Tab>
              <li className="react-tabs__tab" style={{paddingTop:"8px", paddingBottom:"8px", backgroundColor:"rgb(219, 219, 219)", fontWeight:"600"}}>Privacidad</li>
              <Tab>Contraseña</Tab>
              <Tab>Email</Tab>
            </TabList>
            <TabPanel className="card-datos">
              <div className="col-lg-8 h-100 w-100">
                <div className="card mb-4 h-100">
                  <div className="card-body">
                  { user ? <>
                    <div className="row">
                      <div className="col-sm-3" style={{maxWidth: "150px"}}>
                        <p className="mb-0">ID Usuario</p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">{user.id}</p>
                      </div>
                    </div>
                    <hr/>
                    <div className="row">
                      <div className="col-sm-3" style={{maxWidth: "150px"}}>
                        <p className="mb-0">Usuario</p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">{user.nombre}</p>
                      </div>
                    </div>
                    <hr/>
                    <div className="row">
                      <div className="col-sm-3" style={{maxWidth: "150px"}}>
                        <p className="mb-0">Email</p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">{user.email}</p>
                      </div>
                    </div>
                  </> : <></>}
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel className="card-datos">
            <div className="col-lg-8 h-100 w-100">
              <div className="card mb-4 h-100">
                <div className="card-body">
                  <p style={{fontWeight:"500", backgroundColor:"whitesmoke", width:"fit-content", padding:"5px 10px", borderRadius:"5px"}}
                    role="button" data-bs-toggle="collapse" data-bs-target="#cambiarPassword" aria-expanded="false" aria-controls="cambiarPassword"
                    onClick={() => {setErrorFormulario(""); setMensajeCorrecto(""); setPasswordForm(""); setPasswordNuevaForm(""); setPasswordRepetirForm("")}}>Cambiar contraseña</p>
                  <div className="collapse" id="cambiarPassword">
                    {errorFormulario !== "" ? 
                      <div className="alert alert-danger alert-dismissible fade show" role="alert">{errorFormulario}
                        <button onClick={() => {setErrorFormulario("") }} type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                      </div> : <></>
                    }
                    {mensajeCorrecto !== "" ? 
                      <div className="alert alert-success alert-dismissible fade show" role="alert">{mensajeCorrecto}
                        <button onClick={() => {setMensajeCorrecto("") }} type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                      </div> : <></>
                    }
                    <label htmlFor="passwordActual" className="form-label required">Contraseña actual</label>
                    <input type="password" className="form-control w-auto" id="passwordActual" value={passwordForm} onChange={e => {setPasswordForm(e.target.value)}}/>
                    <label htmlFor="passwordNueva" className="form-label required">Nueva contraseña</label>
                    <input type="password" className="form-control w-auto" id="passwordNueva" value={passwordNuevaForm} onChange={e => {setPasswordNuevaForm(e.target.value)}}/>
                    <label htmlFor="passwordRepetir" className="form-label required">Repetir contraseña</label>
                    <input type="password" className="form-control w-auto" id="passwordRepetir" value={passwordRepetirForm} onChange={e => {setPasswordRepetirForm(e.target.value)}}/>
                    <button type="submit" className="btn btn-primary d-block mt-4" onClick={() => {cambiarPassword()}}>Guardar</button>
                  </div>
                </div>
              </div>
            </div>
            </TabPanel>
            <TabPanel className="card-datos">
              <div className="col-lg-8 h-100 w-100">
                <div className="card mb-4 h-100">
                  <div className="card-body">
                    <p style={{fontWeight:"500", backgroundColor:"whitesmoke", width:"fit-content", padding:"5px 10px", borderRadius:"5px"}}
                      role="button" data-bs-toggle="collapse" data-bs-target="#cambiarEmail" aria-expanded="false" aria-controls="cambiarEmail"
                      onClick={() => {setErrorFormulario(""); setMensajeCorrecto(""); setEmailForm("");}}>Cambiar email</p>
                    <div className="collapse" id="cambiarEmail">
                      {errorFormulario !== "" ? 
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">{errorFormulario}
                          <button onClick={() => {setErrorFormulario("") }} type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div> : <></>
                      }
                      {mensajeCorrecto !== "" ? 
                        <div className="alert alert-success alert-dismissible fade show" role="alert">{mensajeCorrecto}
                          <button onClick={() => {setMensajeCorrecto("") }} type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div> : <></>
                      }
                      <label htmlFor="emailNuevo" className="form-label">Nuevo email</label>
                      <input type="text" className="form-control w-auto" id="emailNuevo" value={emailForm} onChange={e => {setEmailForm(e.target.value)}}/>
                      <button type="submit" className="btn btn-primary d-block mt-4" onClick={() => {cambiarEmail()}}>Guardar</button>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default Perfil
