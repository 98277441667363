
function ModalPago () {

    return (
        <div className="modal fade" id="pagoModal" tabIndex={-1} aria-labelledby="pagoModal" aria-hidden="true" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Indique cantidad</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <label htmlFor="datafonoFisicoCantidad" className="form-label">Cantidad (en euros)</label>
                        <div className="input-group mb-3">
                            <input type="number" className="form-control" id="datafonoFisicoCantidad" placeholder="23" aria-label="Cantidad" aria-describedby="basic-addon2"/>
                            <span className="input-group-text" id="basic-addon2">€</span>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                        <button type="button" className="btn btn-primary">Enviar</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalPago;
