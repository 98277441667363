import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface UserState {
  username: any;
  accessToken: any;
  estadoUser: any;
  pageTitle: any;
  firmaCapturadaBase64: any;
  firmaMensajeError: any;
  abrirScada: any;
  scadaUrl: any;
  perfil: any;
}

const initialState: UserState = {
  username: "",
  accessToken: "",
  estadoUser: 0,
  pageTitle: "",
  firmaCapturadaBase64: "",
  firmaMensajeError: "",
  abrirScada: false,
  scadaUrl: "",
  perfil: ""
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUsernameStore: (state, str) => {
      state.username = str.payload;
    },
    setAccessTokenStore: (state, str) => {
      state.accessToken = str.payload;
    },
    setEstadoUserStore: (state, arr) => {
      state.estadoUser = arr.payload;
    },
    setPageTitle: (state, str) => {
      state.pageTitle = str.payload;
    },
    setFirmaCapturadaBase64: (state, str) => {
      state.firmaCapturadaBase64 = str.payload;
    },
    setFirmaMensajeError: (state, str) => {
      state.firmaMensajeError = str.payload;
    },
    setAbrirScada: (state, str) => {
      state.abrirScada = str.payload;
    },
    setScadaUrl: (state, str) => {
      state.scadaUrl = str.payload;
    },
    setPerfil: (state, str) => {
      state.perfil = str.payload;
    }
  },
});

export const { setUsernameStore, setAccessTokenStore, setEstadoUserStore, setPageTitle, setFirmaCapturadaBase64, setFirmaMensajeError, setAbrirScada, setScadaUrl, setPerfil } = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUsername = (state: RootState) => state.user.username;
export const selectAccessToken = (state: RootState) => state.user.accessToken;
export const selectEstadoUser = (state: RootState) => state.user.estadoUser;
export const selectpageTitle = (state: RootState) => state.user.pageTitle;
export const selectFirmaCapturadaBase64 = (state: RootState) => state.user.firmaCapturadaBase64;
export const selectFirmaMensajeError = (state: RootState) => state.user.firmaMensajeError;
export const selectAbrirScada = (state: RootState) => state.user.abrirScada;
export const selectScadaUrl = (state: RootState) => state.user.scadaUrl;
export const selectPerfil = (state: RootState) => state.user.perfil;

export default userSlice.reducer;
